import React from 'react';
import * as S from './styles';
import * as PS from '../styles';
import './styles.css';
import { FaTimes } from 'react-icons/fa';
import { emailValidation } from '../../../../utils/email-validator';
import {
  DEFAULT_FONT_FAMILY,
  PROJECT_NAME_LENGTH_LIMIT,
  SECONDARY_PURPLE_COLOR,
  TEXT_COLOR_NEUTRAL_1,
  TEXT_COLOR_NEUTRAL_2
} from '../../../../constants';

import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import { StyledAlert } from '../../main/myComponents';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ClearIcon from '@material-ui/icons/Clear';
import SnackBar from '../../../atoms/Snackbar/index';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    border-radius: 10px;
    width: 350px;
  }
`;

let saveAlert = '';


const StyledMuiCheckbox = withStyles({
  root: {
    color: TEXT_COLOR_NEUTRAL_2,
    '&$checked': {
      color: SECONDARY_PURPLE_COLOR
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

class DesignerAssistPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailfocusOn: false,
      phone: '',
      phonefocusOn: false,
      notes: '',
      firstName: '',
      firstNamefocusOn: false,
      lastNamefocusOn: false,
      lastName: '',
      first: false,
      second: false,
      projectName: '',
      isSnackBarOpen: false,
      snackBarMessage: "",
      question1: false,
      question2: false,
      question3: false,
      question4: false,
    };
  }
  componentWillReceiveProps(newProps) {
    const email = sessionStorage.getItem('email');
    if (email !== null && newProps.visible !== this.props.visible) this.setState({ email });
    const phone = sessionStorage.getItem('phone');
    if (phone !== null && newProps.visible !== this.props.visible) this.setState({ phone });
    const firstName = sessionStorage.getItem('firstName');
    if (firstName !== null && newProps.visible !== this.props.visible) this.setState({ firstName });
    const lastName = sessionStorage.getItem('lastName');
    if (lastName !== null && newProps.visible !== this.props.visible) this.setState({ lastName });
  }
  _submit() {
    const {
      email,
      phone,
      first,
      second,
      notes,
      firstName,
      lastName,
      question1,
      question2,
      question3,
      question4
    } = this.state;

    if (!emailValidation(email)) {
      let msg;
      if (email === '' && emailCheck) msg = 'Type your email address';
      else if (!emailValidation(email) && emailCheck)
        msg = 'Type your valid email address';
      else if (phone === '' && phoneCheck) msg = 'Type your phone number';
      this.setState({isSnackBarOpen: true, snackBarMessage: msg})
      return;
    }
    var issue_type = (first ? '1' : '0') + '|' + (second ? '2' : '0');
    this.props.designerAssist({ email, phone, issue_type, notes, question1, question2, question3, question4 });
    this.closePanel();
  }
  closePanel() {
    this.props.handleDesignerAssist(false);
    this.setState({
      email: '',
      phone: '',
      notes: ''
    });
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  handlePhoneChange(e) {
    this.setState({
      phone: e.target.validity.valid ? e.target.value : this.state.phone
    });
  }
  handleCheckChange(event) {
    this.setState({ [event.target.value]: event.target.checked });
  }
  render() {
    let { visible } = this.props;
    let {
      email,
      phone,
      notes,
      firstName,
      lastName,
      firstNamefocusOn,
      lastNamefocusOn,
      emailfocusOn,
      phonefocusOn,
      question1,
      question2,
      question3,
      question4
    } = this.state;

    // let { email, phone, emailCheck, phoneCheck, notes, first, second } = this.state;

    return (
      <StyledDialog open={visible} onClose={() => this.closePanel()}>
        <SnackBar isOpen={this.state.isSnackBarOpen} message={this.state.snackBarMessage} handleClose={()=>this.setState({isSnackBarOpen:false, snackBarMessage: ""})} />
        <S.DialogTitle>Request Assistance</S.DialogTitle>
        <S.DialogContent>
          Would you like to be fast-tracked to a <strong>designer</strong>?
        </S.DialogContent>
        <S.DialogContentSelection>
          <S.StyledInputWrapper>
            <S.StyledInput
              value={email}
              onChange={e => this.setState({ email: e.target.value })}
              onFocus = {e => this.setState({ emailfocusOn: true })}
              onBlur = {e => this.setState({ emailfocusOn: false })}
              required
            />
            {!emailfocusOn && !!!email.length && <S.StyledInputLabel style={{pointerEvents: 'none'}}>Email Address</S.StyledInputLabel>}
            {(emailfocusOn || !!email.length) && <S.StyledInputHeadLabel style={{color: !emailfocusOn && TEXT_COLOR_NEUTRAL_2}}>Email Address</S.StyledInputHeadLabel>}
            {emailfocusOn && !!!email.length && <S.StyledInputLabel>Enter email address</S.StyledInputLabel>}
            {!!email.length && <S.PlusImage maskImage="/assets/img/svg/bottombar/arrow-plus.svg" onClick={() => this.setState({ email: '' })} />}
          </S.StyledInputWrapper>
          <S.StyledInputWrapper>
            <S.StyledInput
              value={phone}
              onChange={e => this.setState({ phone: e.target.validity.valid ? e.target.value : this.state.phone })}
              onFocus = {e => this.setState({ phonefocusOn: true })}
              onBlur = {e => this.setState({ phonefocusOn: false })}
              pattern={"[0-9]*"}
              maxLength={50}
              required
            />
            {!phonefocusOn && !!!phone.length && <S.StyledInputLabel style={{pointerEvents: 'none'}}>Phone Number</S.StyledInputLabel>}
            {(phonefocusOn || !!phone.length) && <S.StyledInputHeadLabel style={{color: !phonefocusOn && TEXT_COLOR_NEUTRAL_2}}>Phone Number</S.StyledInputHeadLabel>}
            {phonefocusOn && !!!phone.length && <S.StyledInputLabel>Enter phone number</S.StyledInputLabel>}
            {!!phone.length && <S.PlusImage maskImage="/assets/img/svg/bottombar/arrow-plus.svg" onClick={() => this.setState({ phone: '' })} />}
          </S.StyledInputWrapper>
          <S.StyledInputWrapper>
          <S.StyledInput
            value={firstName}
            onChange={e => this.setState({ firstName: e.target.value })}
            onFocus = {e => this.setState({ firstNamefocusOn: true })}
            onBlur = {e => this.setState({ firstNamefocusOn: false })}
            required
          />
            {!firstNamefocusOn && !!!firstName.length && <S.StyledInputLabel style={{pointerEvents: 'none'}}>First Name(optional)</S.StyledInputLabel>}
            {(firstNamefocusOn || !!firstName.length) && <S.StyledInputHeadLabel style={{color: !firstNamefocusOn && TEXT_COLOR_NEUTRAL_2}}>First Name</S.StyledInputHeadLabel>}
            {firstNamefocusOn && !!!firstName.length && <S.StyledInputLabel>Enter first name</S.StyledInputLabel>}
            {!!firstName.length && <S.PlusImage maskImage="/assets/img/svg/bottombar/arrow-plus.svg" onClick={() => this.setState({ firstName: '' })} />}
          </S.StyledInputWrapper>
          <S.StyledInputWrapper>
            <S.StyledInput
              value={lastName}
              onChange={e => this.setState({ lastName: e.target.value })}
              onFocus = {e => this.setState({ lastNamefocusOn: true })}
              onBlur = {e => this.setState({ lastNamefocusOn: false })}
              required
            />
            {!lastNamefocusOn && !!!lastName.length && <S.StyledInputLabel style={{pointerEvents: 'none'}}>Last Name(optional)</S.StyledInputLabel>}
            {(lastNamefocusOn || !!lastName.length) && <S.StyledInputHeadLabel  style={{color: !lastNamefocusOn && TEXT_COLOR_NEUTRAL_2}}>Last Name</S.StyledInputHeadLabel>}
            {lastNamefocusOn && !!!lastName.length && <S.StyledInputLabel>Enter last name</S.StyledInputLabel>}
            {!!lastName.length && <S.PlusImage maskImage="/assets/img/svg/bottombar/arrow-plus.svg" onClick={() => this.setState({ lastName: '' })} />}
          </S.StyledInputWrapper>
          <S.DialogContents>Do you have any specific issues</S.DialogContents>
          <FormControlLabel
            margin-left="10px"
            control={
              <StyledMuiCheckbox
                style={{ padding: 'unset', margin: '0 10px' }}
                checked={question1}
                onChange={() => this.setState({ question1: !question1 })}
                value={question1}
              />
            }
            label={<S.DialogContents>I don't have measurements</S.DialogContents>}
          />
          <FormControlLabel
            margin-left="10px"
            control={
              <StyledMuiCheckbox
                style={{ padding: 'unset', margin: '0 10px' }}
                checked={question2}
                onChange={() => this.setState({ question2: !question2 })}
                value={question2}
              />
            }
            label={<S.DialogContents>I need help building the layout</S.DialogContents>}
          />
          <FormControlLabel
            margin-left="10px"
            control={
              <StyledMuiCheckbox
                style={{ padding: 'unset', margin: '0 10px' }}
                checked={question3}
                onChange={() => this.setState({ question3: !question3 })}
                value={question3}
              />
            }
            label={<S.DialogContents>I need help building the floorplan</S.DialogContents>}
          />
          <FormControlLabel
            style = {{alignItems : "flex-start"}}
            margin-left="10px"
            control={
              <StyledMuiCheckbox
                style={{ padding: 'unset', margin: '0 10px' }}
                checked={question4}
                onChange={() => this.setState({ question4: !question4 })}
                value={question4}
              />
            }
            label={<S.DialogContents>I need help with completing and submitting the project</S.DialogContents>}
          />
          <div style={{width: '100%', marginTop: 8 }}>
            <S.DialogTextArea
              value={notes}
              maxLength={200}
              placeholder="Notes(optional)"
              onChange={e => this.setState({ notes: e.target.value })}
            />
            <span style={{ fontFamily: DEFAULT_FONT_FAMILY, fontSize: 14, color: TEXT_COLOR_NEUTRAL_1, float: 'right' }} >{notes.length}/200 characters</span>
          </div>
        </S.DialogContentSelection>
        <S.DialogAction>
          <S.Button onClick={() => this.closePanel()}>Cancel</S.Button>
          <S.Button onClick={() => this._submit()}>OK</S.Button>
        </S.DialogAction>
      </StyledDialog>
      // <div style={{ width: '100%', height: '100%', display: visible ? 'block' : 'none', userSelect: 'none' }}>
      //   <S.Div>
      //     <div style={{ backgroundColor: '#1183B7', height: '30px', paddingRight: '6px' }}>
      //       <div style={{ float: 'right', marginTop: '4px' }} onClick={() => this.closePanel()}>
      //         <PS.IconButton><FaTimes /></PS.IconButton>
      //       </div>
      //     </div>
      //     <div style={{ fontSize: 14, marginTop: 20, marginLeft: 40 }}>
      //       Would you like to be fast-tracked to a Designer?<br/>
      //       What is the best way to reach you for immediate contact?
      //     </div>
      //     <div style={{ fontSize: 12, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
      //       <div style={{ paddingTop: 10 }}>
      //         <PS.DialogCheckbox
      //           checked={ this.state.emailCheck }
      //           onClick = { (e) => this.handleCheckChange (e) }
      //           value="emailCheck"
      //           inputProps={{
      //             'aria-label': 'primary checkbox'
      //           }}
      //         />
      //         <label
      //           className="container"
      //           style={{
      //             paddingLeft: '0'
      //           }}
      //         >
      //           E-mail:
      //         </label>
      //         <input value={email} style={{ width: 250, marginLeft: 30 }} onChange={e => this.handleEmailChange(e)} disabled={!emailCheck} />
      //       </div>
      //       <div style={{ paddingTop: 20 }}>
      //         <PS.DialogCheckbox
      //           checked={ this.state.phoneCheck }
      //           onClick = { (e) => this.handleCheckChange (e) }
      //           value="phoneCheck"
      //           inputProps={{
      //             'aria-label': 'primary checkbox'
      //           }}
      //         />
      //         <label
      //           className="container"
      //           style={{
      //             paddingLeft: '0'
      //           }}
      //         >
      //           Phone:
      //         </label>
      //         <input value={phone} style={{ width: 250, marginLeft: 30 }} onChange={e => this.handlePhoneChange(e)} disabled={!phoneCheck} pattern={"[0-9]*"} />
      //       </div>
      //     </div>
      //     <div style={{ fontSize: 14, marginTop: 10, marginLeft: 80, alignItems: 'center'}}>
      //       Specify the type of issue you are having
      //     </div>
      //     <div style={{ fontSize: 12, display: 'flex', flexDirection: 'column', position: 'relative', marginLeft: '80px' }}>
      //       <div style={{ paddingTop: 12 }}>
      //         <PS.DialogCheckbox
      //           checked={ this.state.second }
      //           onClick = { (e) => this.handleCheckChange (e) }
      //           value="second"
      //           inputProps={{
      //             'aria-label': 'primary checkbox'
      //           }}
      //         />
      //         <label
      //           className="container"
      //           style={{
      //             paddingLeft: '0'
      //           }}
      //         >
      //           I don't have measurements
      //         </label>
      //       </div>
      //       <div style={{ paddingTop: 12 }}>
      //         <PS.DialogCheckbox
      //           checked={ this.state.first }
      //           onClick = { (e) => this.handleCheckChange (e) }
      //           value="first"
      //           inputProps={{
      //             'aria-label': 'primary checkbox'
      //           }}
      //         />
      //         <label
      //           className="container"
      //           style={{
      //             paddingLeft: '0'
      //           }}
      //         >
      //           Other
      //         </label>
      //       </div>
      //       <div style={{ paddingTop: '0px', width: '340px' }}>
      //         <div style={{ marginTop: '8px' }}>
      //           <textarea value={notes} style={{ width: '100%', height: '100%', resize: 'none' }} disabled={!first} rows={10} onChange={e => this.setState({ notes: e.target.value })} />
      //         </div>
      //       </div>
      //     </div>
      //     <div style={{ width: '70px', marginLeft: '350px', marginTop: '10px', cursor: 'pointer' }} onClick={() => this._submit()}>
      //       <img width={'80px'} style={{ float: 'right' }} src={'/assets/img/svg/toolbar/use_button.svg'} />
      //       <S.ButtonTitle>
      //         {'SUBMIT'}
      //       </S.ButtonTitle>
      //     </div>
      //   </S.Div>
      // </div>
    );
  }
}

export default DesignerAssistPopup;
