import React from 'react'
import { Input, Button, Row } from 'antd'
import { Link } from 'react-router-dom';
import {API_SERVER_URL} from '../../../constants';
import history from '../../../@history';
import axios from 'axios';
import '../style.css';

class RegisterForm extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      username    : '',
      userid      : '',
      role        : 'customer',
      password    : '',
      confirmPwd  : '',
    }
  }

  handleSubmit (e) {
    e.preventDefault();

    const {confirmPwd, ...values} = this.state;
    if (values.password !== confirmPwd) {
      alert('The passwords do not match');
      return;
    }

    axios.post(`${API_SERVER_URL}/api/user/insert`, { ...values })
    .then(response => {
      if(response.data.success) {
        alert("Successfully registered");
        this.setState({ username: '', userid: '', role: 'customer', password: '', confirmPwd: '' });
      }
      else {
        alert("Username already exists");
      }
    })
  }

  handleUsernameChange(e) {
    e.target.setCustomValidity("");
    this.setState({username: e.target.value});
  }
  handleUserIDChange(e) {
    e.target.setCustomValidity("");
    this.setState({userid: e.target.value});
  }
  handleUserRoleChange(e) {
    this.setState({role: e.target.value});
  }
  handlePasswordChange(e) {
    e.target.setCustomValidity("");
    this.setState({password: e.target.value});
  }
  handleConfirmChange(e) {
    e.target.setCustomValidity("");
    this.setState({confirmPwd: e.target.value});
  }
  
  handleInputInvalid(e, text) {
    e.target.setCustomValidity("");
    if (!e.target.validity.valid) {
        e.target.setCustomValidity(text);
    }
  }

  render() {
    const {username, userid, role, password, confirmPwd} = this.state;
    const {type} = this.props;
    return (
      <form onSubmit={e=>this.handleSubmit(e)} className="register-form">
        <Row className="register-form-row">
          <label className="form-label mb-0">Username</label>
            <Input  
              onChange={e => this.handleUsernameChange(e)}
              onInvalid={e => this.handleInputInvalid(e, "Please enter your name")}
              placeholder="Username" required
              value={username}
            />
        </Row>
        <Row className="register-form-row">
          <label className="form-label mb-0">User ID</label>
            <Input
              onChange={e => this.handleUserIDChange(e)}
              onInvalid={e => this.handleInputInvalid(e, 'Please enter your ID.')}
              placeholder="User ID" required
              value={userid}
            />
        </Row>
        <Row className="register-form-row">
          <label className="form-label mb-0">User Role</label>
            <select
              onChange={e => this.handleUserRoleChange(e)}
              value={role}
            >
              <option value="customer"> customer </option>
              <option value="designer"> designer </option>
            </select>
        </Row>
        <Row className="register-form-row">
          <label className="form-label mb-0">Password</label>
            <Input
              onChange={e => this.handlePasswordChange(e)}
              onInvalid={e => this.handleInputInvalid(e, 'Please confirm your password.')}
              type="password"
              placeholder="Password"
              value={password}
            />
        </Row>
        <Row className="register-form-row">
          <label className="form-label mb-0">Confirm</label>
            <Input
              onChange={e => this.handleConfirmChange(e)}
              onInvalid={e => this.handleInputInvalid(e, 'Please confirm your password.')}
              type="password"
              placeholder="Confirm Password"
              value={confirmPwd}
            />
        </Row>
        <Row className="login-form-row">
          <div className="divider">-</div>
        </Row>
        <div className="form-actions" style={{width: '90%', display: 'flex', justifyContent: 'space-around'}}>
          <Link className="register-link-btn" to='' onClick={e => history.goBack()}> Back </Link>
          
          <Button type="primary" htmlType="submit" className="login-form-button">
            {type === 'register' ? 'Register' : 'Add User'}
          </Button>

          <Link className="register-link-btn" to='/login'> Sign In </Link>
        </div>
      </form>
    )    
  }
}

export default RegisterForm;
