import { fromJS } from 'immutable';
import { Layer, Vertex, Group } from './export';
import {
  IDBroker,
  NameGenerator
} from '../utils/export';

class Area {

  static add(state, layerID, type, verticesCoords, catalog) {
    let area;

    let layer = state.getIn(['scene', 'layers', layerID]);
    let areaID;
    
    areaID = IDBroker.acquireID();
    let vertices = verticesCoords.map((v) => {
      let retVal = Vertex.add(state, layerID, v.x, v.y, 'areas', areaID);
      state = retVal.updatedState;
      return retVal.vertex.id;
    });
    area = catalog.factoryElement(type, {
      id: areaID,
      name: NameGenerator.generateName('areas', catalog.getIn(['elements', type, 'info', 'title'])),
      type,
      prototype: 'areas',
      vertices
    });
    state = state.setIn(['scene', 'layers', layerID, 'areas', areaID], area);
    // set default texture//
    let properties = area.properties;
    let JsProperty = properties.toJS();
    JsProperty.texture = "parquet"; // set parquet as default texture
    state = this.setJsProperties(state, layerID, areaID, JsProperty).updatedState;
    // /////////////////////
    return { updatedState: state, area };
  }

  static select(state, layerID, areaID) {
    state = Layer.select(state, layerID).updatedState;
    state = Layer.selectElement(state, layerID, 'areas', areaID).updatedState;

    return { updatedState: state };
  }

  static remove(state, layerID, areaID) {

    let area = state.getIn(['scene', 'layers', layerID, 'areas', areaID]);

    if (area.get('selected') === true) state = this.unselect(state, layerID, areaID).updatedState;

    area.vertices.forEach(vertexID => { state = Vertex.remove(state, layerID, vertexID, 'areas', areaID).updatedState; });

    state = state.deleteIn(['scene', 'layers', layerID, 'areas', areaID]);

    state.getIn(['scene', 'groups']).forEach(group => state = Group.removeElement(state, group.id, layerID, 'areas', areaID).updatedState);

    return { updatedState: state };
  }

  static unselect(state, layerID, areaID) {
    state = Layer.unselect(state, layerID, 'areas', areaID).updatedState;

    return { updatedState: state };
  }

  static setProperties(state, layerID, areaID, properties) {
    state = state.mergeIn(['scene', 'layers', layerID, 'areas', areaID, 'properties'], properties);

    return { updatedState: state };
  }

  static setFloorStyles(state, texture) {
    let { scene } = state;
    let layerID = scene.get('selectedLayer');
    let layer = scene.layers.get(layerID);

    layer = layer.mergeIn(['floorStyle'], texture);
    state = state.merge({
      scene: scene.mergeIn(['layers', layerID], layer)
    });

    let areas = layer.areas;
    areas.forEach(data => {
      const dataJSON = data.toJS();
      let id = dataJSON.id;
      let newTex = dataJSON.texture;
      newTex = { ...newTex, ...texture };
      data = data.mergeIn(["texture"], newTex);
      layer = layer.mergeIn(["areas", id], data);
      state = state.merge({
        scene: scene.mergeIn(['layers', layerID], layer)
      });
    });
    return { updatedState: state };
  }

  static setJsProperties(state, layerID, areaID, properties) {
    return this.setProperties(state, layerID, areaID, fromJS(properties));
  }

  static updateProperties(state, layerID, areaID, properties) {
    properties.forEach((v, k) => {
      if (state.hasIn(['scene', 'layers', layerID, 'areas', areaID, 'properties', k]))
        state = state.mergeIn(['scene', 'layers', layerID, 'areas', areaID, 'properties', k], v);
    });

    return { updatedState: state };
  }

  static updateJsProperties(state, layerID, areaID, properties) {
    return this.updateProperties(state, layerID, areaID, fromJS(properties));
  }

  static setAttributes(state) {
    return { updatedState: state };
  }

}

export { Area as default };
