import React from 'react';
import * as Three from 'three';
import {loadGLTF} from '../../utils/load-obj';
import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader.js';
import { OBJTYPE_MESH } from '../../../../../src/constants';

const hdrUrls = ['px.hdr', 'nx.hdr', 'py.hdr', 'ny.hdr', 'pz.hdr', 'nz.hdr'];
let textureCube = new HDRCubeTextureLoader()
  .setPath('/catalog/envMap/')
  .load(hdrUrls, function () {
  textureCube.magFilter = Three.LinearFilter;
  textureCube.needsUpdate = true;
});

let cached3DDoor = null;

const STYLE_STR0 = {fill:'rgb(185, 185, 185)',stroke:'#494949',strokeWidth:'1', strokeMiterlimit:'2.61313'};
const STYLE_STR0_S = {fill:'rgb(185, 185, 185)',stroke:'#0096fd',strokeWidth:'1', strokeMiterlimit:'2.61313'};
const STYLE_FILL2 =  {fill:'#1183B7'};
const STYLE_FNT0 =  {fill:'white',fontWeight:'normal', fontSize:'13px', fontFamily:'Proxima Nova Rg'}

export default {
  name: 'Framed Doorway',
  prototype: 'holes',

  info: {
    title: 'Framed Doorway',
    tag: ['doorway'],
    description: 'Framed Doorway',
    image: "/assets/img/svg/door/Framed_dorway.svg",
    url: "/assets/gltf/doorway_framed.gltf",
  },

  properties: {
    width: {
      label: 'Width',
      type: 'length-measure',
      defaultValue: {
        length: 91.44
      },
    },
    height: {
      label: 'Height',
      type: 'length-measure',
      defaultValue: {
        length: 215
      },
      length: 215
    },
    altitude: {
      label: 'Altitude',
      type: 'length-measure',
      defaultValue: {
        length: 0
      }
    },
    thickness: {
      label: 'Thickness',
      type: 'length-measure',
      defaultValue: {
        length: 6
      },
    },
    flip_orizzontal: {
      label: 'Flip Door',
      type: 'checkbox',
      defaultValue: false,
      values: {
        'none': false,
        'yes': true
      }
    }
  },

  render2D: function (element, layer, scene) {
    let flip = element.properties.get('flip_orizzontal');
    let lineWidth = 6;
    let holeWidth = element.properties.get('width').get('length');
    let rectStyle = element.selected ? STYLE_STR0_S : STYLE_STR0;
    let length = element.properties.get('width').get('length');
    if(flip == false) {
      return (
        <g transform={`translate(${-length / 2}, 0)`}>
          <rect style={rectStyle} x="0" y={-lineWidth/2} width={holeWidth} height={lineWidth}/>
          {/* <rect style={STYLE_FILL2} x={holeWidth - 16} y="-8.5" width="17" height="17" rx="1.27" ry="1.27"/> */}
          {/* <text x={holeWidth - 15} y="4.5" transform={`scale(1,-1)`} style={STYLE_FNT0}>FD</text> */}
        </g>
      )
    }
    else{
      return (
        <g transform={`translate(${-length / 2}, 0)`}>
          <rect style={rectStyle} x="0" y={-lineWidth/2} width={holeWidth} height={lineWidth}/>
          {/* <rect style={STYLE_FILL2} x={holeWidth - 16} y="-8.5" width="17" height="17" rx="1.27" ry="1.27"/> */}
          {/* <text x={holeWidth - 15} y="4.5"  style={STYLE_FNT0}>FD</text> */}
        </g>
      )
    }
  },

  render3D: function (element, layer, scene) {
    let onLoadItem = (object) => {
      let boundingBox = new Three.Box3().setFromObject(object);

      let initialWidth = boundingBox.max.x - boundingBox.min.x;
      let initialHeight = boundingBox.max.y - boundingBox.min.y;
      let initialThickness = boundingBox.max.z - boundingBox.min.z;

      let width = element.properties.get('width').get('length');
      let height = element.properties.get('height').get('length');
      let thickness = element.properties.get('thickness').get('length');

      const params = {
        envMap: 'HDR',
        roughness: 0.9,
        metalness: 0.1,
        exposure: 1.0,
      };
      const examplecolor = new Three.Color(0xFFFFFF);
      let mat2 = new Three.MeshStandardMaterial({
        color: examplecolor,
        metalness: params.metalness,
        roughness: params.roughness
      });
      // mat2.envMap = textureCube;
      for (let j = 0; j < object.children.length; j++) {
        if (object.children[j].type === OBJTYPE_MESH) {
          object.children[j].material = mat2;
          object.children[j].receiveShadow = true;
        }
      }

      object.scale.set(width / initialWidth, height / initialHeight,
        thickness / initialThickness);

      return object;
    };

    if(cached3DDoor) {
      return Promise.resolve(onLoadItem(cached3DDoor.clone()));
    }

    return loadGLTF(element.url)
    .then(object => {
      cached3DDoor = object;
      return onLoadItem(cached3DDoor.clone())
    })
    // return Promise.resolve(new Three.Object3D());
  }
};
