import React from 'react';
import PropTypes from 'prop-types';
import * as SharedStyle from '../../shared-style';

const STYLE = {
  strokeWidth: 10,
  strokeLinejoin: 'round',
  rx: '2px',
  stroke: SharedStyle.LINE_MESH_COLOR.selected,
  fill: SharedStyle.LINE_MESH_COLOR.selected,
  cursor: "move"
};

export default function Vertex({vertex, layer}) {

  let {x, y} = vertex;
  let relatedVertice = {x, y};

  if(vertex.lines.get(0)){
    let line = layer.lines.get(vertex.lines.get(0));
    if(layer.lines.get(vertex.lines.get(1))!== undefined && layer.lines.get(vertex.lines.get(1)).get('selected')){
      line = layer.lines.get(vertex.lines.get(1));
    }

    if(line){
      const index = line.vertices.findIndex(a=>a === vertex.id);
      if(index > -1){
        const vertice = line.relatedVertices.filter(a=>{
          if(a['index'] === undefined){
            return a.get('index') === index
          }else if(a['index'] !== undefined){
            return a.index === index
          }}).get(0);
        if(vertice && !vertice['point'] ){
          relatedVertice = {x: vertice.get('point').get('x'), y: vertice.get('point').get('y')};
        }else if(vertice !== undefined){
          relatedVertice = vertice['point'];
        }
      }
    }
  }

  return (
    <g
      data-element-root
      data-prototype={vertex.prototype}
      data-id={vertex.id}
      data-selected={vertex.selected}
      data-layer={layer.id}
    >
      <polygon points={`${relatedVertice.x},${relatedVertice.y} ${relatedVertice.x},${y} ${x},${y} ${x},${relatedVertice.y}`} style={STYLE}></polygon>
    </g>
  );
}

Vertex.propTypes = {
  vertex: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
};
