import React, { useState } from 'react';

import { FaSearch, FaPlus, FaAngleLeft } from 'react-icons/fa';
import * as S from '../../styles';

import Appliance from '../../product/appliance';
import {
  API_SERVER,
  MODE_IDLE,
  MODE_3D_VIEW,
  MODE_3D_FIRST_PERSON,
  MODE_VIEWING_CATALOG,
  MODE_CONFIGURING_PROJECT,
  MODE_IDLE_3D,
  MODE_ROTATING_ITEM_3D,
  MODE_DRAGGING_ITEM_3D,
  MODE_2D_PAN,
  MODE_DRAWING_ITEM_3D
} from '../../../../../constants';

export const ApplianceChooseProduct = ({
  setStep,
  action,
  pData,
  bFlag,
  props,
  doorExpand,
  search,
  dData,
  onSearch,
  mode,
  isExpand,
  closeAction
}) => {
  let applianceselect = name => {
    select(props.catalog.elements[name]);
  };

  let select = element => {
    props.projectActions.unselectAll();
    switch (element.prototype) {
      case 'lines':
        props.linesActions.selectToolDrawingLine(element.name);
        break;
      case 'items':
        [
          MODE_IDLE_3D,
          MODE_DRAWING_ITEM_3D,
          MODE_DRAGGING_ITEM_3D,
          MODE_ROTATING_ITEM_3D
        ].includes(mode)
          ? props.itemsActions.selectToolDrawingItem3D(element.name)
          : props.itemsActions.selectToolDrawingItem(element.name);
        break;
      case 'holes':
        props.holesActions.selectToolDrawingHole(element.name);
        break;
    }
    props.itemsActions.updatePopupOpen(3);
    props.projectActions.pushLastSelectedCatalogElementToHistory(element);
    closeAction && closeAction();
  };

  let products = [];

  if (pData.length > 0) {
    for (let i = 0; i < pData.length; i++) {
      products.push(
        <S.ItemWrapper key={i.toString()} style={{ justifyContent: 'center' }}>
          <Appliance
            name={'appliance'}
            onClick={() => {
              applianceselect(pData[i].name);
            }}
            url={pData[i].thumbnail}
            title={pData[i].name}
            selected={pData[i].selected ? pData[i].selected : null}
          />
        </S.ItemWrapper>
      );
    }
  }
  let miHeight = bFlag ? 28 : 0;
  let nonHeight = 495 - miHeight + 'px';
  let height = window.innerHeight * 0.6 - miHeight + 'px';
  return (
    <S.ProductsContentWrapper
      style={{
        width: isExpand === 1 ? 400 : 220,
        height: doorExpand ? height : nonHeight
      }}
    >
      {isExpand === 1 ? (
        <S.ItemWrapper style={{ flexWrap: 'wrap', border: 'none' }}>
          {products}
        </S.ItemWrapper>
      ) : (
        products
      )}
    </S.ProductsContentWrapper>
  );
};
