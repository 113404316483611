import React, { useState } from 'react';

import * as S from './styles';

const STYLE_TITLE = {
  fontSize: '12px',
  color: '#fff',
  padding: '10px',
  // backgroundColor: '#3e3e3f',
  margin: '0px',
  cursor: 'pointer',
  display: 'flex',
  height: '85px',
  justifyContent: 'start',
  alignItems: 'center'
};
// const STYLE_CONTENT = {
//   fontSize: '10px',
//   color: 'rgba(222, 222, 222, 1)',
//   padding: '0px',
//   backgroundColor: '#28292d',
// };
// const STYLE_ARROW = {
//   float: 'right'
// };

export default function Product({ url, title, onClick, name }) {
  return (
    <S.PlanWraper onClick={onClick}>
      <h3
        style={{...STYLE_TITLE, paddingLeft: url ? 15 : 0}}
      >
        {url !== null && (<img name={name} src={url} height={60} />)}
        <span style={{marginLeft: 23}}> {title} </span>
      </h3>
    </S.PlanWraper>
  )
}
