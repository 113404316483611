import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as SharedStyle from '../../shared-style';
import { FaFolderOpen } from 'react-icons/fa';
import { MdSettings, MdUndo, MdDirectionsRun } from 'react-icons/md';

//http://www.cssportal.com/css-tooltip-generator/

const STYLE = {
  width: '30px',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '5px',
  fontSize: '25px',
  position: 'relative',
  cursor: 'pointer'
};

const STYLE_TOOLTIP = {
  position: 'absolute',
  width: '140px',
  color: SharedStyle.COLORS.white,
  background: SharedStyle.COLORS.black,
  height: '30px',
  lineHeight: '30px',
  textAlign: 'center',
  visibility: 'visible',
  borderRadius: '6px',
  opacity: '0.8',
  left: '100%',
  top: '50%',
  marginTop: '-15px',
  marginLeft: '15px',
  zIndex: '999',
  fontSize: '12px'
};

const STYLE_TOOLTIP_PIN = {
  position: 'absolute',
  top: '50%',
  right: '100%',
  marginTop: '-8px',
  width: '0',
  height: '0',
  borderRight: '8px solid #000000',
  borderTop: '8px solid transparent',
  borderBottom: '8px solid transparent'
};

const itemBtnStyle = {
  borderWidth: "1px",
  borderStyle: "solid",
  float: "left",
  cursor: "pointer",
  margin: "5px",
  padding: "4px",
  borderColor: "black"
}

const iconHoverStyle = {
  borderWidth: "1px",
  borderStyle: "solid",
  float: "left",
  cursor: "pointer",
  margin: "5px",
  padding: "4px",
  borderColor: SharedStyle.MATERIAL_COLORS['500'].cyan
}

export default class PluginItem extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = { active: false };
  }

  render() {
    let { state, props } = this;
    let placeholder;
    let url = this.props.url;
    let pname;
    if (props.name.length > 8) {
      pname = props.name.slice(0, 5);
      pname += '...';
    }
    else {
      pname = props.name;
    }
    if (url == 'before')
      placeholder = (<MdUndo style={{ fontSize: "50px", color: SharedStyle.PRIMARY_COLOR.icon }} />);
    if (url == 'dir')
      placeholder = (<FaFolderOpen style={{ fontSize: "50px", color: SharedStyle.PRIMARY_COLOR.icon }} />);
    if (url !== 'before' && url !== 'dir')
      placeholder = (<img src={this.props.url} style={{ width: "50px", height: "50px" }}></img>);
    return (
      <div title={props.name} onClick={props.onClick.bind(this, props.url, props.name, props.gltfUrl)}
      onMouseOut={event => this.setState({ active: false })}
      onMouseOver={event => this.setState({ active: true })}
      style={this.state.active?iconHoverStyle:itemBtnStyle}>
        {placeholder}
        <p style={{ margin: 0, textAlign: "center", fontSize: "70%" }}>{pname}</p>
      </div>
    )
  }
}
