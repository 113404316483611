import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

//http://www.cssportal.com/css-tooltip-generator/

export default class ToolbarButton extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = { hover: false };
  }

  render() {
    let { state, props } = this;
    const {active, onClick, children, src, title, ...otherProps} = props;
    let overlayStyle = {}
    if (state.hover)
      overlayStyle = { ...overlayStyle, 'display': 'block'}
      // overlayStyle = { ...overlayStyle, 'display': 'block', 'background': SharedStyle.TOOLBAR_COLOR.focus }

    if (props.active)
      overlayStyle = { ...overlayStyle, 'display': 'block'}
      // overlayStyle = { ...overlayStyle, 'display': 'block', 'background': SharedStyle.TOOLBAR_COLOR.active }
    return (
      <S.Main
        {...otherProps}
        onMouseOver={event => this.setState({ hover: true })}
        onMouseOut={event => this.setState({ hover: false })}
        onClick={props.onClick}
      >
        <div>
          {props.children}
        </div>

        <S.Overlay style={{...overlayStyle}}>
          <img width="75px" src={props.src} />
          <S.Title>{props.title}</S.Title>
        </S.Overlay>
        {/* {
          state.active ?
          <div style={STYLE_TOOLTIP}>
            <span style={STYLE_TOOLTIP_PIN} />
            {props.tooltip}
          </div>
          : null
        } */}
      </S.Main>
    )
  }
}

ToolbarButton.propTypes = {
  title: PropTypes.string,
  hover: PropTypes.bool,
  active: PropTypes.bool.isRequired,
  tooltip: PropTypes.string.isRequired,
  onClick: PropTypes.func
};
