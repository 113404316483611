import {
  UPDATE_2D_CAMERA,
  SELECT_TOOL_PAN,
  SELECT_TOOL_ZOOM_IN,
  SELECT_TOOL_ZOOM_OUT,
  MODE_2D_PAN,
  MODE_2D_ZOOM_IN,
  MODE_2D_ZOOM_OUT,
  CHANGE_WALL_LENGTH_MEASURE,
  CHANGE_BASE_CABINET_MEASURE,
  CHANGE_WALL_CABINET_MEASURE,
  CHANGE_WINDOW_DOOR_MEASURE,
  UPDATE_CEIL_HEIGHT,
  UPDATE_CEIL_HEIGHT_UNIT
} from '../constants';
import convert from 'convert-units';

export default function (state, action) {
  let {scene} = state;
  switch (action.type) {
    case UPDATE_CEIL_HEIGHT_UNIT:{
      let { scene } = state;
      let selectedLayer = scene.selectedLayer;
      let layer = scene.layers.get(selectedLayer);
      let newLayer = layer.merge({ unit: action.value });
      state = state.mergeIn(['scene', 'layers', selectedLayer], newLayer);
      return state;
    }
    case UPDATE_CEIL_HEIGHT:{
      let selectedLayer = scene.selectedLayer;
      let layer = scene.layers.get(selectedLayer);
      let newLayer = layer.merge({ ceilHeight: action.value });
      state = state.mergeIn(['scene', 'layers', selectedLayer], newLayer);
      let items = state.scene.layers.get(state.scene.selectedLayer).items;
      let newItems = items.map(item => {
        if(item.type.includes('Light')) {
          let height = item.properties.getIn(['height', '_length']);
          let heightUnit = item.properties.getIn(['height', '_unit']);
          let newAltitude = action.value - convert(height).from(heightUnit).to('in');
          item = item.setIn(['properties', 'altitude', '_length'], convert(newAltitude).from('in').to(layer.unit));
        }
        return item;
      })
      state = state.mergeIn(['scene', 'layers', selectedLayer, 'items'], newItems);
      return state;
    }
    case UPDATE_2D_CAMERA:
      return state.merge({ viewer2D: action.value });
    case CHANGE_WALL_LENGTH_MEASURE:
      return state.merge({scene: scene.merge({showWallLengthMeasure : action.value})});
    case CHANGE_BASE_CABINET_MEASURE:
      return state.merge({scene: scene.merge({showBaseCabinetMeasure : action.value})});
    case CHANGE_WALL_CABINET_MEASURE:
      return state.merge({scene: scene.merge({showWallCabinetMeasure : action.value})});
    case CHANGE_WINDOW_DOOR_MEASURE:
      return state.merge({scene: scene.merge({showWindowDoorMeasure : action.value})});
    case SELECT_TOOL_PAN:
      return state.set('mode', MODE_2D_PAN);

    case SELECT_TOOL_ZOOM_IN:
      return state.set('mode', MODE_2D_ZOOM_IN);

    case SELECT_TOOL_ZOOM_OUT:
      return state.set('mode', MODE_2D_ZOOM_OUT);
  }
}
