import React, { useState } from 'react';

import { FaSearch, FaPlus, FaAngleLeft } from 'react-icons/fa';

import * as S from '../../styles';
import Appliance from '../../product/appliance';
import ProductLine from '../../product/productline';
import { ARRAY_3D_MODES } from '../../../../../constants';

export const CabinetChooseProduct = ({ setStep, chooseAction, closeAction, pData, bFlag, props, doorExpand, search, dData, onSearch, mode, isExpand }) => {
  let temp = [];

  for (let i = 0; i < pData.length; i++) {
    if (pData[i].name.indexOf(search) != -1)
      temp.push(pData[i]);
  }

  pData = temp;
  let cabinetselect = (name) => {
    select(props.catalog.elements[name]);
  }
  let select = (element) => {
    props.projectActions.unselectAll();
    switch (element.prototype) {
      case 'lines':
        props.linesActions.selectToolDrawingLine(element.name);
        break;
      case 'items':
        ARRAY_3D_MODES.includes(mode) ?
          props.itemsActions.selectToolDrawingItem3D(element.name) :
          props.itemsActions.selectToolDrawingItem(element.name);
        break;
      case 'holes':
        props.holesActions.selectToolDrawingHole(element.name);
        break;
    }
    props.itemsActions.updatePopupOpen(1);
    props.projectActions.pushLastSelectedCatalogElementToHistory(element);
    closeAction && closeAction();
  }

  let products = [];


  if (pData.length > 0) {
    let name = pData[0].type == "cabinet" ? "cabinet" : "dir";
    let blMolding = pData[0].asset.endsWith('.svg') ? 1 : 0;
    if (!bFlag) {
      for (let i = 0; i < pData.length; i++) {
        if (!blMolding)
          products.push(
              <section key={i.toString()} style={{ width: '100%', position: 'relative' }}>
                <ProductLine
                  name={name}
                  onClick={() => { cabinetselect(pData[i].name); }}
                  url={pData[i].thumbnail}
                  title={pData[i].long_name}
                  info={pData[i].info}
                  hascds={pData[i].hascds}
                >
                </ProductLine>
                {!pData[i].hascds && <S.UnAvailableText>Unavailable</S.UnAvailableText>}
              </section>
          );
        else
          products.push(
                <Appliance
                  key={i.toString()} 
                  name={'finishing'}
                  onClick={() => {
                    chooseAction(pData[i]);
                  }}
                  selected={pData[i].selected ? pData[i].selected : null}
                  url={pData[i].thumbnail}
                  title={pData[i].name}
                  category={pData[i].category}
              />
          )
      }
    }
  }

  let miHeight = bFlag ? 28 : 0;
  let nonHeight = (495 - miHeight) + "px";
  let height = (window.innerHeight * 0.6 - miHeight) + "px";
  return (
    <S.ProductsContentWrapper style={{ width: (isExpand === 1) ? 400 : 220, height: doorExpand ? height : nonHeight }}>
      {isExpand === 1 ? <S.ItemWrapper style={{ flexWrap: 'wrap', border: 'none' }}>{products}</S.ItemWrapper> : products}
    </S.ProductsContentWrapper>
  )
}
