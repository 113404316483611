import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { amber, green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import {
  TEXT_COLOR_NEUTRAL_0,
  PRIMARY_GREEN_COLOR,
  DEFAULT_FONT_FAMILY,
} from '../../../constants';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, ...other } = props;

  return (
    <SnackbarContent
      className={clsx(className)}
      aria-describedby="client-snackbar"
      message={message}
      action={
        <Button style={{color: PRIMARY_GREEN_COLOR, fontSize: 16, fontWeight: 'bold', fontFamily: DEFAULT_FONT_FAMILY}} onClick={onClose}>
          OK
        </Button>
      }
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.object,
  onClose: PropTypes.func,
};

export function StyledAlert(props) {
  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    props.onClose();
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      style={{transform: 'unset'}}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <MySnackbarContentWrapper
        onClose={handleClose}
        style={{
          position: 'fixed',
          top: 90,
          borderRadius: 6,
          textAlign: 'center',
          zIndex: 11,
          height: 60,
          backgroundColor: TEXT_COLOR_NEUTRAL_0,
        }}
        message= { props.text === "" ? <span style={{fontSize: 16, fontFamily: DEFAULT_FONT_FAMILY}}>Press <strong>ESC</strong> key to finish drawing!</span> : props.text }
      />
    </Snackbar>
  );
}