import { Map, List, fromJS } from 'immutable';
import { Layer, Group } from './export';

import {
  IDBroker,
  NameGenerator
} from '../utils/export';

import {
  nearestSnap,
  addLineSegmentSnap,
} from '../utils/snap';

import {
  MODE_IDLE,
  MODE_IDLE_3D,
  MODE_DRAWING_HOLE,
  MODE_DRAWING_HOLE_3D,
  MODE_DRAGGING_HOLE,
  MODE_DRAGGING_HOLE_3D,
} from '../constants';

import {
  GeometryUtils
} from '../utils/export';

class Hole {

  static create(state, layerID, type, lineID, offset, properties) {
    let holeID = IDBroker.acquireID();
    let hole = state.catalog.factoryElement(type, {
      id: holeID,
      url:  state.catalog.getIn(['elements', type, 'info', 'url']),
      type,
      offset,
      line: lineID
    }, properties);

    state = state.setIn(['scene', 'layers', layerID, 'holes', holeID], hole);
    state = state.updateIn(['scene', 'layers', layerID, 'lines', lineID, 'holes'],
      holes => holes.push(holeID));
    // get vertex order/////
    let element = state.scene.layers.getIn([layerID, "lines", lineID]);
    let layer = state.getIn(["scene", "layers", layerID]);
    let vetName0 = element.vertices.get(0);
    let vetName1 = element.vertices.get(1);
    let verticesArray = [];
    layer.areas.forEach(data => {
      verticesArray.push(data.vertices.toJS());
    });
    for (let i = 0; i < verticesArray.length; i++) {
      let vertices = verticesArray[i];
      if (vertices.includes(vetName0) && vertices.includes(vetName1)) {
        let index0 = vertices.indexOf(vetName0);
        let index1 = vertices.indexOf(vetName1);
        // if vertex order chanege//
        if (index1 + 1 == index0) {
          return { updatedState: state, hole };
        }
        if (index0 == 0 && index1 == (vertices.length - 1)) {
          return { updatedState: state, hole };
        }
        // ////////////////////////
      }
    }
    // ///////////////////////////
    properties = hole.properties.toJS();
    if (properties.hasOwnProperty("flip_orizzontal")) {
      properties["flip_orizzontal"] = true;
    }
    if (properties.hasOwnProperty("flip_horizontal")) {
      properties["flip_horizontal"] = true;
    }
    state = this.setJsProperties(state, layerID, holeID, properties).updatedState;
    return { updatedState: state, hole };
  }

  static select(state, layerID, holeID) {
    state = Layer.select(state, layerID).updatedState;
    state = Layer.selectElement(state, layerID, 'holes', holeID).updatedState;

    return { updatedState: state };
  }

  static remove(state, layerID, holeID) {
    let hole = state.getIn(['scene', 'layers', layerID, 'holes', holeID]);
    state = this.unselect(state, layerID, holeID).updatedState;
    state = Layer.removeElement(state, layerID, 'holes', holeID).updatedState;

    state = state.updateIn(['scene', 'layers', layerID, 'lines', hole.line, 'holes'], holes => {
      let index = holes.findIndex(ID => holeID === ID);
      return index !== -1 ? holes.remove(index) : holes;
    });

    state.getIn(['scene', 'groups']).forEach(group => state = Group.removeElement(state, group.id, layerID, 'holes', holeID).updatedState);

    return { updatedState: state };
  }

  static unselect(state, layerID, holeID) {
    state = Layer.unselect(state, layerID, 'holes', holeID).updatedState;

    return { updatedState: state };
  }

  static selectToolDrawingHole(state, sceneComponentType) {
    let snapElements = (new List()).withMutations(snapElements => {
      let { lines, vertices } = state.getIn(['scene', 'layers', state.scene.selectedLayer]);

      lines.forEach(line => {
        let { x: x1, y: y1 } = vertices.get(line.vertices.get(0));
        let { x: x2, y: y2 } = vertices.get(line.vertices.get(1));
        addLineSegmentSnap(snapElements, x1, y1, x2, y2, 20, 1, line.id);
      });
    });
    
    state = state.merge({
      mode: MODE_DRAWING_HOLE,
      snapElements,
      drawingSupport: Map({
        type: sceneComponentType
      })
    });

    return { updatedState: state };
  }

  static selectToolDrawingHole3D(state, sceneComponentType) {
    let snapElements = (new List()).withMutations(snapElements => {
      let { lines, vertices } = state.getIn(['scene', 'layers', state.scene.selectedLayer]);

      lines.forEach(line => {
        let { x: x1, y: y1 } = vertices.get(line.vertices.get(0));
        let { x: x2, y: y2 } = vertices.get(line.vertices.get(1));
        addLineSegmentSnap(snapElements, x1, y1, x2, y2, 20, 1, line.id);
      });
    });
    
    state = state.merge({
      mode: MODE_DRAWING_HOLE_3D,
      snapElements,
      drawingSupport: Map({
        type: sceneComponentType
      })
    });
    state = state.mergeIn(['scene', 'loadFlag'], false);

    return { updatedState: state };
  }

  static updateDrawingHole(state, layerID, x, y) {
    let catalog = state.catalog;
    //calculate snap and overwrite coords if needed
    //force snap to segment
    let snap = nearestSnap(state.snapElements, x, y, state.snapMask.merge({ SNAP_SEGMENT: true }));
    if (snap) ({ x, y } = snap.point);

    let selectedHole = state.getIn(['scene', 'layers', layerID, 'selected', 'holes']).first();

    if (snap) {
      let lineID = snap.snap.related.get(0);
      let vertices = state.getIn(['scene', 'layers', layerID, 'lines', lineID, 'vertices']);
      let { x: x1, y: y1 } = state.getIn(['scene', 'layers', layerID, 'vertices', vertices.get(0)]);
      let { x: x2, y: y2 } = state.getIn(['scene', 'layers', layerID, 'vertices', vertices.get(1)]);
     
      // // I need min and max vertices on this line segment
      let minVertex = GeometryUtils.minVertex({ x: x1, y: y1 }, { x: x2, y: y2 });
      let maxVertex = GeometryUtils.maxVertex({ x: x1, y: y1 }, { x: x2, y: y2 });
      
      let width = catalog.factoryElement(state.drawingSupport.get('type')).properties.getIn(['width', 'length']);

      // // Now I need min and max possible coordinates for the hole on the line. They depend on the width of the hole
      let lineLength = GeometryUtils.pointsDistance(x1, y1, x2, y2);
      let alpha = GeometryUtils.absAngleBetweenTwoPoints(x1, y1, x2, y2);
      let cosAlpha = GeometryUtils.cosWithThreshold(Math.abs(alpha), 0.0000001);
      let sinAlpha = GeometryUtils.sinWithThreshold(Math.abs(alpha), 0.0000001);
      let minLeftVertexHole = {
        x: minVertex.x + (state.mode.includes('ING_HOLE') ? width + 1 : width) / 2 * cosAlpha,
        y: minVertex.y + (state.mode.includes('ING_HOLE') ? width + 1 : width) / 2 * sinAlpha
      };

      let maxRightVertexHole = {
        x: minVertex.x + lineLength * cosAlpha - (state.mode.includes('ING_HOLE') ? width + 1 : width) / 2 * cosAlpha,
        y: minVertex.y + lineLength * sinAlpha - (state.mode.includes('ING_HOLE') ? width + 1 : width) / 2 * sinAlpha
      };

      let offset;
      if (x < minLeftVertexHole.x) {
        offset = GeometryUtils.pointPositionOnLineSegment(minVertex.x, minVertex.y,
          maxVertex.x, maxVertex.y,
          minLeftVertexHole.x, minLeftVertexHole.y);
      } else if (x > maxRightVertexHole.x) {
        offset = GeometryUtils.pointPositionOnLineSegment(minVertex.x, minVertex.y,
          maxVertex.x, maxVertex.y,
          maxRightVertexHole.x, maxRightVertexHole.y);
      } else {

        if (x === minLeftVertexHole.x && x === maxRightVertexHole.x) {
          if (y < minLeftVertexHole.y) {
            offset = GeometryUtils.pointPositionOnLineSegment(minVertex.x, minVertex.y,
              maxVertex.x, maxVertex.y,
              minLeftVertexHole.x, minLeftVertexHole.y);
            offset = minVertex.x === x1 && minVertex.y === y1 ? offset : 1 - offset;
          } else if (y > maxRightVertexHole.y) {
            offset = GeometryUtils.pointPositionOnLineSegment(minVertex.x, minVertex.y,
              maxVertex.x, maxVertex.y,
              maxRightVertexHole.x, maxRightVertexHole.y);
            offset = minVertex.x === x1 && minVertex.y === y1 ? offset : 1 - offset;
          } else {
            offset = GeometryUtils.pointPositionOnLineSegment(x1, y1, x2, y2, x, y);
          }
        } else {
          offset = GeometryUtils.pointPositionOnLineSegment(x1, y1, x2, y2, x, y);
        }
      }

      if (this.checkHoleCollide(state.getIn(['scene', 'layers', layerID]), lineID, selectedHole, x, y, width)) {
        return { updatedState: state };
      }

      //if hole does exist, update
      if (state.getIn(['drawingSupport', 'currentID'])) {
        let hole = state.getIn(['scene', 'layers', layerID, 'holes', state.getIn(['drawingSupport', 'currentID'])]);
        hole = hole.merge({
          offset:offset,
          line: lineID,
          x: x,
          y: y,
          rotation: alpha
        });
        state = state.mergeIn(['scene', 'layers', layerID, 'holes', state.getIn(['drawingSupport', 'currentID'])], hole);
        //remove from old line ( if present )
        let index = state.getIn(['scene', 'layers', layerID, 'lines']).findEntry(line => {
          return line.id !== lineID && line.get('holes').contains(state.getIn(['drawingSupport', 'currentID']));
        });

        if (index) {
          let removed = index[1].get('holes').filter(hl => hl !== state.getIn(['drawingSupport', 'currentID']));
          state = state.setIn(['scene', 'layers', layerID, 'lines', index[0], 'holes'], removed);
        }

      //   //add to line
        let line_holes = state.getIn(['scene', 'layers', layerID, 'lines', lineID, 'holes']);
        if (!line_holes.contains(state.getIn(['drawingSupport', 'currentID']))) {
          state = state.setIn(['scene', 'layers', layerID, 'lines', lineID, 'holes'], line_holes.push(state.getIn(['drawingSupport', 'currentID'])));
        }
        // get vertex order/////
        let element = state.scene.layers.getIn([layerID, "lines", lineID]);

        let layer = state.getIn(["scene", "layers", layerID]);
        let vetName0 = element.vertices.get(0);
        let vetName1 = element.vertices.get(1);
        let verticesArray = [];
        layer.areas.forEach(data => {
          verticesArray.push(data.vertices.toJS());
        });
        for (let i = 0; i < verticesArray.length; i++) {
          let vertices = verticesArray[i];
          if (vertices.includes(vetName0) && vertices.includes(vetName1)) {
            let index0 = vertices.indexOf(vetName0);
            let index1 = vertices.indexOf(vetName1);
            // if vertex order chanege//
            if (index1 + 1 == index0) {
              if (hole != undefined) {

                let properties = hole.properties.toJS();
                if (properties.hasOwnProperty("flip_orizzontal")) {
                  properties["flip_orizzontal"] = false;
                }
                if (properties.hasOwnProperty("flip_horizontal")) {
                  properties["flip_horizontal"] = false;
                }
                state = this.setJsProperties(state, layerID, state.getIn(['drawingSupport', 'currentID']), properties).updatedState;
              }
              return { updatedState: state };
            }
            if (index0 == 0 && index1 == (vertices.length - 1)) {
              if (hole != undefined) {

                let properties = hole.properties.toJS();
                if (properties.hasOwnProperty("flip_orizzontal")) {
                  properties["flip_orizzontal"] = false;
                }
                if (properties.hasOwnProperty("flip_horizontal")) {
                  properties["flip_horizontal"] = false;
                }
                state = this.setJsProperties(state, layerID, state.getIn(['drawingSupport', 'currentID']), properties).updatedState;
              }
              return { updatedState: state };
            }
            // ////////////////////////
          }
        }
        // ///////////////////////////
        if (hole != undefined) {
          let properties = hole.properties.toJS();
          if (properties.hasOwnProperty("flip_orizzontal")) {
            properties["flip_orizzontal"] = true;
          }
          if (properties.hasOwnProperty("flip_horizontal")) {
            properties["flip_horizontal"] = true;
          }

          state = this.setJsProperties(state, layerID, state.getIn(['drawingSupport', 'currentID']), properties).updatedState;
        }
      } 
      else {
        //if hole does not exist, create
        let { updatedState: stateH, hole } = this.create(state, layerID, state.drawingSupport.get('type'), lineID, offset);
        state = Hole.select(stateH, layerID, hole.id).updatedState;
        state = state.setIn(['drawingSupport', 'currentID'], hole.id);
      }
    }
    //i've lost the snap while trying to drop the hole
    else if (false && selectedHole) 
     //think if enable
    {
      state = Hole.remove(state, layerID, selectedHole).updatedState;
    }

    return { updatedState: state };
  }

  static updateDrawingHole3D(state, layerID, x, y) {
    let catalog = state.catalog;
    //calculate snap and overwrite coords if needed
    //force snap to segment
    let snap = nearestSnap(state.snapElements, x, y, state.snapMask.merge({ SNAP_SEGMENT: true }));
    if (snap) ({ x, y } = snap.point);

    let selectedHole = state.getIn(['scene', 'layers', layerID, 'selected', 'holes']).first();

    if (snap) {
      let lineID = snap.snap.related.get(0);
      let vertices = state.getIn(['scene', 'layers', layerID, 'lines', lineID, 'vertices']);
      let { x: x1, y: y1 } = state.getIn(['scene', 'layers', layerID, 'vertices', vertices.get(0)]);
      let { x: x2, y: y2 } = state.getIn(['scene', 'layers', layerID, 'vertices', vertices.get(1)]);
     
      // // I need min and max vertices on this line segment
      let minVertex = GeometryUtils.minVertex({ x: x1, y: y1 }, { x: x2, y: y2 });
      let maxVertex = GeometryUtils.maxVertex({ x: x1, y: y1 }, { x: x2, y: y2 });
      
      let width = catalog.factoryElement(state.drawingSupport.get('type')).properties.getIn(['width', 'length']);

      // // Now I need min and max possible coordinates for the hole on the line. They depend on the width of the hole
      let lineLength = GeometryUtils.pointsDistance(x1, y1, x2, y2);
      let alpha = GeometryUtils.absAngleBetweenTwoPoints(x1, y1, x2, y2);
      let cosAlpha = GeometryUtils.cosWithThreshold(Math.abs(alpha), 0.0000001);
      let sinAlpha = GeometryUtils.sinWithThreshold(Math.abs(alpha), 0.0000001);
      let minLeftVertexHole = {
        x: minVertex.x + (state.mode.includes('ING_HOLE_3D') ? width + 1 : width) / 2 * cosAlpha,
        y: minVertex.y + (state.mode.includes('ING_HOLE_3D') ? width + 1 : width) / 2 * sinAlpha
      };

      let maxRightVertexHole = {
        x: minVertex.x + lineLength * cosAlpha - (state.mode.includes('ING_HOLE_3D') ? width + 1 : width) / 2 * cosAlpha,
        y: minVertex.y + lineLength * sinAlpha - (state.mode.includes('ING_HOLE_3D') ? width + 1 : width) / 2 * sinAlpha
      };

      let offset;
      if (x < minLeftVertexHole.x) {
        offset = GeometryUtils.pointPositionOnLineSegment(minVertex.x, minVertex.y,
          maxVertex.x, maxVertex.y,
          minLeftVertexHole.x, minLeftVertexHole.y);
      } else if (x > maxRightVertexHole.x) {
        offset = GeometryUtils.pointPositionOnLineSegment(minVertex.x, minVertex.y,
          maxVertex.x, maxVertex.y,
          maxRightVertexHole.x, maxRightVertexHole.y);
      } else {

        if (x === minLeftVertexHole.x && x === maxRightVertexHole.x) {
          if (y < minLeftVertexHole.y) {
            offset = GeometryUtils.pointPositionOnLineSegment(minVertex.x, minVertex.y,
              maxVertex.x, maxVertex.y,
              minLeftVertexHole.x, minLeftVertexHole.y);
            offset = minVertex.x === x1 && minVertex.y === y1 ? offset : 1 - offset;
          } else if (y > maxRightVertexHole.y) {
            offset = GeometryUtils.pointPositionOnLineSegment(minVertex.x, minVertex.y,
              maxVertex.x, maxVertex.y,
              maxRightVertexHole.x, maxRightVertexHole.y);
            offset = minVertex.x === x1 && minVertex.y === y1 ? offset : 1 - offset;
          } else {
            offset = GeometryUtils.pointPositionOnLineSegment(x1, y1, x2, y2, x, y);
          }
        } else {
          offset = GeometryUtils.pointPositionOnLineSegment(x1, y1, x2, y2, x, y);
        }
      }

      if (this.checkHoleCollide(state.getIn(['scene', 'layers', layerID]), lineID, selectedHole, x, y, width)) {
        return { updatedState: state };
      }

      //if hole does exist, update
      if (state.getIn(['drawingSupport', 'currentID'])) {
        let hole = state.getIn(['scene', 'layers', layerID, 'holes', state.getIn(['drawingSupport', 'currentID'])]);
        hole = hole.merge({
          offset:offset,
          line: lineID,
          x: x,
          y: y,
          rotation: alpha
        });
        state = state.mergeIn(['scene', 'layers', layerID, 'holes', state.getIn(['drawingSupport', 'currentID'])], hole);

        //remove from old line ( if present )
        let index = state.getIn(['scene', 'layers', layerID, 'lines']).findEntry(line => {
          return line.id !== lineID && line.get('holes').contains(state.getIn(['drawingSupport', 'currentID']));
        });

        if (index && state.mode !== "MODE_DRAWING_HOLE_3D") {
          let removed = index[1].get('holes').filter(hl => hl !== state.getIn(['drawingSupport', 'currentID']));
          state = state.setIn(['scene', 'layers', layerID, 'lines', index[0], 'holes'], removed);
        }

        //add to line
        let line_holes = state.getIn(['scene', 'layers', layerID, 'lines', lineID, 'holes']);
        if (!line_holes.contains(state.getIn(['drawingSupport', 'currentID'])) && state.mode !== "MODE_DRAWING_HOLE_3D") {
          state = state.setIn(['scene', 'layers', layerID, 'lines', lineID, 'holes'], line_holes.push(state.getIn(['drawingSupport', 'currentID'])));
        }

        // get vertex order/////
        let element = state.scene.layers.getIn([layerID, "lines", lineID]);

        let layer = state.getIn(["scene", "layers", layerID]);
        let vetName0 = element.vertices.get(0);
        let vetName1 = element.vertices.get(1);
        let verticesArray = [];
        layer.areas.forEach(data => {
          verticesArray.push(data.vertices.toJS());
        });
        for (let i = 0; i < verticesArray.length; i++) {
          let vertices = verticesArray[i];
          if (vertices.includes(vetName0) && vertices.includes(vetName1)) {
            let index0 = vertices.indexOf(vetName0);
            let index1 = vertices.indexOf(vetName1);
            // if vertex order chanege//
            if (index1 + 1 == index0) {
              if (hole != undefined) {

                let properties = hole.properties.toJS();
                if (properties.hasOwnProperty("flip_orizzontal")) {
                  properties["flip_orizzontal"] = false;
                }
                if (properties.hasOwnProperty("flip_horizontal")) {
                  properties["flip_horizontal"] = false;
                }
                state = this.setJsProperties(state, layerID, state.getIn(['drawingSupport', 'currentID']), properties).updatedState;
                if (state.mode === "MODE_DRAWING_HOLE_3D") {
                  state = state.setIn(['scene', 'layers', layerID, 'holes', state.getIn(['drawingSupport', 'currentID']), "visible"], false);
                } else {
                  state = state.setIn(['scene', 'layers', layerID, 'holes', state.getIn(['drawingSupport', 'currentID']), "visible"], true);
                }
              }
              return { updatedState: state };
            }
            if (index0 == 0 && index1 == (vertices.length - 1)) {
              if (hole != undefined) {

                let properties = hole.properties.toJS();
                if (properties.hasOwnProperty("flip_orizzontal")) {
                  properties["flip_orizzontal"] = false;
                }
                if (properties.hasOwnProperty("flip_horizontal")) {
                  properties["flip_horizontal"] = false;
                }
                state = this.setJsProperties(state, layerID, state.getIn(['drawingSupport', 'currentID']), properties).updatedState;
                if (state.mode === "MODE_DRAWING_HOLE_3D") {
                  state = state.setIn(['scene', 'layers', layerID, 'holes', state.getIn(['drawingSupport', 'currentID']), "visible"], false);
                } else {
                  state = state.setIn(['scene', 'layers', layerID, 'holes', state.getIn(['drawingSupport', 'currentID']), "visible"], true);
                }
              }
              return { updatedState: state };
            }
            // ////////////////////////
          }
        }
        // ///////////////////////////
        if (hole != undefined) {
          let properties = hole.properties.toJS();
          if (properties.hasOwnProperty("flip_orizzontal")) {
            properties["flip_orizzontal"] = true;
          }
          if (properties.hasOwnProperty("flip_horizontal")) {
            properties["flip_horizontal"] = true;
          }

          state = this.setJsProperties(state, layerID, state.getIn(['drawingSupport', 'currentID']), properties).updatedState;
          if (state.mode === "MODE_DRAWING_HOLE_3D") {
            state = state.setIn(['scene', 'layers', layerID, 'holes', state.getIn(['drawingSupport', 'currentID']), "visible"], false);
          } else {
            state = state.setIn(['scene', 'layers', layerID, 'holes', state.getIn(['drawingSupport', 'currentID']), "visible"], true);
          }
        }
      }
      else {
        //if hole does not exist, create
        let { updatedState: stateH, hole } = this.create(state, layerID, state.drawingSupport.get('type'), lineID, offset);
        state = Hole.select(stateH, layerID, hole.id).updatedState;
        state = state.setIn(['drawingSupport', 'currentID'], hole.id);
      }
    }
    if (state.mode !== 'MODE_DRAWING_HOLE_3D') {
      state = state
        .getIn(['scene', 'layers', layerID, 'holes'])
        .reduce((oldReduceState, element) => {
          oldReduceState = oldReduceState.setIn(
            ['scene', 'layers', layerID, 'holes', element.id, 'visible'],
            true
          );
          return oldReduceState;
        }, state);
    }
    return { updatedState: state };
  }

  static endCreatingHole(state) {
    state = state.merge({
      isOpen: false,
      openedType: 0,
    });
    return { updatedState: state };
  }
  static updatePopupOpen(state, value) {
    state = state.merge({
      openedType: value,
    });
    return { updatedState: state };
  }

  static endDrawingHole(state, layerID, x, y) {
    state = this.updateDrawingHole(state, layerID, x, y).updatedState;
    state = Layer.unselectAll(state, layerID).updatedState;
    let popup = state.get('popup');
    state = state.merge({
      drawingSupport: Map({
        type: state.drawingSupport.get('type')
      }),
      isOpen: !popup,
      mode: MODE_IDLE
    });
    state = Layer.unselectAll(state, layerID).updatedState;
    return { updatedState: state };
  }

  static endDrawingHole3D(state, layerID, x, y) {
    state = state.merge({
      mode: MODE_IDLE_3D
    });
    state = this.updateDrawingHole3D(state, layerID, x, y).updatedState;
    state = Layer.unselectAll(state, layerID).updatedState;
    let popup = state.get('popup');
    state = state.merge({
      drawingSupport: Map({
        type: state.drawingSupport.get('type')
      }),
      isOpen: !popup,
      mode: MODE_IDLE_3D
    });
    return { updatedState: state };
  }

  static beginDraggingHole(state, layerID, holeID, x, y) {
    let layer = state.getIn(['scene', 'layers', layerID]);
    let hole = layer.getIn(['holes', holeID]);
    let line = layer.getIn(['lines', hole.line]);
    let v0 = layer.getIn(['vertices', line.vertices.get(0)]);
    let v1 = layer.getIn(['vertices', line.vertices.get(1)]);

    let snapElements = addLineSegmentSnap(List(), v0.x, v0.y, v1.x, v1.y, 9999999, 1, null);

    state = state.merge({
      mode: MODE_DRAGGING_HOLE,
      snapElements,
      draggingSupport: Map({
        layerID,
        holeID,
        startPointX: x,
        startPointY: y,
      })
    });

    return { updatedState: state };
  }

  static beginDraggingHole3D(state, layerID, holeID, x, y) {
    let layer = state.getIn(['scene', 'layers', layerID]);
    let hole = layer.getIn(['holes', holeID]);
    let line = layer.getIn(['lines', hole.line]);
    let v0 = layer.getIn(['vertices', line.vertices.get(0)]);
    let v1 = layer.getIn(['vertices', line.vertices.get(1)]);

    let snapElements = addLineSegmentSnap(List(), v0.x, v0.y, v1.x, v1.y, 9999999, 1, null);

    state = state.updateIn(['scene', 'layers', layerID, 'lines', hole.line, 'holes'], holes => {
      let index = holes.findIndex(ID => holeID === ID);
      return index !== -1 ? holes.remove(index) : holes;
    });

    state = state.merge({
      mode: MODE_DRAGGING_HOLE_3D,
      snapElements,
      draggingSupport: Map({
        layerID,
        holeID,
        startPointX: x,
        startPointY: y,
      })
    });

    return { updatedState: state };
  }

  static updateDraggingHole(state, x, y) {

    //calculate snap and overwrite coords if needed
    //force snap to segment
    let snap = nearestSnap(state.snapElements, x, y, state.snapMask.merge({ SNAP_SEGMENT: true }));
    if (!snap) return state;

    let { draggingSupport, scene } = state;

    let layerID = draggingSupport.get('layerID');
    let holeID = draggingSupport.get('holeID');
    let startPointX = draggingSupport.get('startPointX');
    let startPointY = draggingSupport.get('startPointY');

    let layer = state.getIn(['scene', 'layers', layerID]);
    let hole = layer.getIn(['holes', holeID]);
    let line = layer.getIn(['lines', hole.line]);
    let v0 = layer.getIn(['vertices', line.vertices.get(0)]);
    let v1 = layer.getIn(['vertices', line.vertices.get(1)]);
    ({ x, y } = snap.point);

    // I need min and max vertices on this line segment
    let minVertex = GeometryUtils.minVertex(v0, v1);
    let maxVertex = GeometryUtils.maxVertex(v0, v1);

    // Now I need min and max possible coordinates for the hole on the line. They depend on the width of the hole

    let width = hole.properties.get('width').get('length');
    let lineLength = GeometryUtils.pointsDistance(v0.x, v0.y, v1.x, v1.y);
    let alpha = Math.atan2(v1.y - v0.y, Math.abs(v1.x - v0.x));

    let cosWithThreshold = (alpha) => {
      let cos = Math.cos(Math.abs(alpha));
      return cos < 0.0000001 ? 0 : cos;
    };

    let sinWithThreshold = (alpha) => {
      let sin = Math.sin(Math.abs(alpha));
      return sin < 0.0000001 ? 0 : sin;
    };

    let cosAlpha = cosWithThreshold(Math.abs(alpha));
    let sinAlpha = sinWithThreshold(Math.abs(alpha));

    let minLeftVertexHole = {
      x: minVertex.x + (state.mode.includes('ING_HOLE') ? width + 1 : width) / 2 * cosAlpha,
      y: minVertex.y + (state.mode.includes('ING_HOLE') ? width + 1 : width) / 2 * sinAlpha
    };

    let maxRightVertexHole = {
      x: minVertex.x + lineLength * cosAlpha - (state.mode.includes('ING_HOLE') ? width + 1 : width) / 2 * cosAlpha,
      y: minVertex.y + lineLength * sinAlpha - (state.mode.includes('ING_HOLE') ? width + 1 : width) / 2 * sinAlpha
    };

    // Now I need to verify if the snap vertex (with coordinates x and y) is on the line segment

    let offset;

    if (x < minLeftVertexHole.x) {
      // Snap point is previous the the line
      offset = GeometryUtils.pointPositionOnLineSegment(minVertex.x, minVertex.y,
        maxVertex.x, maxVertex.y,
        minLeftVertexHole.x, minLeftVertexHole.y);
    } else {
      // Snap point is after the line or on the line
      if (x > maxRightVertexHole.x) {
        offset = GeometryUtils.pointPositionOnLineSegment(minVertex.x, minVertex.y,
          maxVertex.x, maxVertex.y,
          maxRightVertexHole.x, maxRightVertexHole.y);
      } else if (x === minLeftVertexHole.x && x === maxRightVertexHole.x) {
        // I am on a vertical line, I need to check y coordinates
        if (y < minLeftVertexHole.y) {
          offset = GeometryUtils.pointPositionOnLineSegment(minVertex.x, minVertex.y,
            maxVertex.x, maxVertex.y,
            minLeftVertexHole.x, minLeftVertexHole.y);

          offset = minVertex === v0 ? offset : 1 - offset;

        } else if (y > maxRightVertexHole.y) {
          offset = GeometryUtils.pointPositionOnLineSegment(minVertex.x, minVertex.y,
            maxVertex.x, maxVertex.y,
            maxRightVertexHole.x, maxRightVertexHole.y);

          offset = minVertex === v0 ? offset : 1 - offset;

        } else {
          offset = GeometryUtils.pointPositionOnLineSegment(minVertex.x, minVertex.y,
            maxVertex.x, maxVertex.y,
            x, y);

          offset = minVertex === v0 ? offset : 1 - offset;
        }
      } else {
        offset = GeometryUtils.pointPositionOnLineSegment(minVertex.x, minVertex.y,
          maxVertex.x, maxVertex.y,
          x, y);
      }
    }

    if (this.checkHoleCollide(layer, hole.line, holeID, x, y, width)) {
      return { updatedState: state };
    }

    hole = hole.set('offset', offset);
    hole = hole.merge({
      x:x,
      y:y,
      rotation:alpha
    });
    state = state.merge({
      scene: scene.mergeIn(['layers', layerID, 'holes', holeID], hole)
    });

    return { updatedState: state };
  }

  static updateDraggingHoleChanged(state, x, y, layerID, holeID) {
    let { scene } = state;

    let hole = scene.getIn(['layers', layerID, 'holes', holeID]);
    hole = hole.merge({
      x: x,
      y: y,
    });

    state = state.merge({
      scene: scene.mergeIn(['layers', layerID, 'holes', holeID], hole),
    });

    return { updatedState: state };
  }

  static updateDraggingHoleRulerChanged(state, width, layerID, holeID) {
    let { scene } = state;
    state = state.mergeIn(['scene', 'layers', layerID, 'holes', holeID, 'properties', 'width', 'length'], width);

    return { updatedState: state };
  }

  static endDraggingHole3D(state, x, y) {
    if (state === undefined) return;
    state = this.updateDraggingHole(state, x, y).updatedState;
    let { draggingSupport } = state;
    if (draggingSupport.size === 0) return { updatedState: state };
    let layerID = draggingSupport.get('layerID');
    let holeID = draggingSupport.get('holeID');
    let layer = state.getIn(['scene', 'layers', layerID]);
    let hole = layer.getIn(['holes', holeID]);
    state = state.updateIn(['scene', 'layers', layerID, 'lines', hole.line, 'holes'], holes => {
      let index = holes.findIndex(ID => holeID === ID);
      return index === -1 ? holes.push (holeID) : holes;
    });
    state = state.merge({
      mode: MODE_IDLE_3D,
      draggingSupport: Map({})
    });

    return { updatedState: state };
  }

  static checkHoleCollide(layer, lineID, selectedHoleID, hx, hy, hw) {
    let line = layer.getIn(['lines', lineID]);
    let holes = line.getIn(['holes']);

    let bCollide = holes.some(holeID => {
      if (holeID == selectedHoleID) return false;

      let hole = layer.getIn(['holes', holeID]);
      let dx = hole.get('x') - hx;
      let dy = hole.get('y') - hy;
      let dw = (hole.properties.get('width').get('length') + hw) / 2;
      if (dx*dx + dy*dy < dw*dw) {
        return true;
      }
    })

    return bCollide;
  }

  static endDraggingHole(state, x, y) {
    state = this.updateDraggingHole(state, x, y).updatedState;
    state = state.merge({ mode: MODE_IDLE });

    return { updatedState: state };
  }

  static setProperties(state, layerID, holeID, properties) {
    state = state.setIn(['scene', 'layers', layerID, 'holes', holeID, 'properties'], properties);

    return { updatedState: state };
  }

  static setJsProperties(state, layerID, holeID, properties) {
    return this.setProperties(state, layerID, holeID, fromJS(properties));
  }

  static updateProperties(state, layerID, holeID, properties) {
    properties.forEach((v, k) => {
      if (state.hasIn(['scene', 'layers', layerID, 'holes', holeID, 'properties', k]))
        state = state.mergeIn(['scene', 'layers', layerID, 'holes', holeID, 'properties', k], v);
    });

    return { updatedState: state };
  }

  static updateJsProperties(state, layerID, holeID, properties) {
    return this.updateProperties(state, layerID, holeID, fromJS(properties));
  }

  static setAttributes(state, layerID, holeID, holesAttributes) {

    let hAttr = holesAttributes.toJS();
    let { offsetA, offsetB, offset } = hAttr;

    delete hAttr['offsetA'];
    delete hAttr['offsetB'];
    delete hAttr['offset'];

    let misc = new Map({ _unitA: offsetA._unit, _unitB: offsetB._unit });

    state = state
      .mergeIn(['scene', 'layers', layerID, 'holes', holeID], fromJS(hAttr))
      .mergeDeepIn(['scene', 'layers', layerID, 'holes', holeID], new Map({ offset, misc }));

    return { updatedState: state };
  }

}

export { Hole as default };
