import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  ContentTitle,
  ContentContainer,
  FormLabel,
  FormBlock,
  FormNumberInput,
  FormSubmitButton,
  CancelButton
} from '../style/export';
import SnackBar from '../atoms/Snackbar/index';

export default class CustomConfigurator extends Component {

  constructor(props, context) {
    super(props, context);

    let scene = props.state.scene;

    this.state = {
      dataWidth: scene.width,
      dataHeight: scene.height,
      isSnackBarOpen: false,
      message: ""
    };
  }

  onSubmit(event) {
    event.preventDefault();

    let {projectActions} = this.context;

    let {dataWidth, dataHeight} = this.state;
    dataWidth = parseInt(dataWidth);
    dataHeight = parseInt(dataHeight);
    if (dataWidth <= 100 || dataHeight <= 100) {
      this.setState({
        isSnackBarOpen:true,
        snackBarMessage: "Scene size too small"
      })
    } else {
      projectActions.setProjectProperties({width: dataWidth, height: dataHeight});
    }
  }


  render() {
    let {width, height} = this.props;
    let {dataWidth, dataHeight} = this.state;
    let {projectActions, translator} = this.context;

    return (
      <ContentContainer width={width} height={height}>
        <SnackBar isOpen={this.state.isSnackBarOpen} message={this.state.snackBarMessage} handleClose={()=>this.setState({isSnackBarOpen:false, snackBarMessage: ""})} />
      </ContentContainer>
    )
  }
}

CustomConfigurator.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  state: PropTypes.object.isRequired,
};

CustomConfigurator.contextTypes = {
  projectActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
};
