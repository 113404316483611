import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormNumberInput from '../../../style/form-number-input';
import convert from 'convert-units';
import ConfirmPopup from './confirm-popup';
import styled from 'styled-components';
import {
  DEFAULT_FONT_FAMILY,
  TEXT_COLOR_NEUTRAL_0,
  TEXT_COLOR_NEUTRAL_2,
  TEXT_COLOR_NEUTRAL_3
} from '../../../../constants';

const NumberInputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  position: relative;
`

const NumberInputTitle = styled.span`
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size : 16px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: ${TEXT_COLOR_NEUTRAL_0};
  margin-right: auto;
`

const NumberInputMeasure = styled.span`
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 16px;
  font-weight: 600;
  line-height: 17px;
  color: ${TEXT_COLOR_NEUTRAL_3};
  position: absolute;
  right: 10px;
  bottom: 14px;
`

const Left = 0;
const Right = 180;
const Back = 90;
const Front = -90;

let newX, newY;

export default function ItemAttributesEditor({ element, onUpdate, propertiesFormData, catalog, attributeFormData, onConfigUpdate, state, internalState, filteredProperties, shouldOnlyShowEditorOptions=false, ...rest }, { translator }) {
  let [isPopupOpen, setPopupOpen] = useState(false);
  let name = attributeFormData.has('element') ? attributeFormData.get('element').get('name') : element.name;
  let renderedX = attributeFormData.has('element') ? attributeFormData.get('element').get('x') : element.x;
  let renderedY = attributeFormData.has('element') ? attributeFormData.get('element').get('y') : element.y;
  let renderedR = attributeFormData.has('element') ? attributeFormData.get('element').get('rotation') : element.rotation;

  let distBack, distFront, distLeft, distRight;
  if (element.distArray[0] == undefined) {
    for(let i = 0; i < 4; i++){
      switch(element.distArray.get(i).get(1)) {
        case Left:
          distLeft = convert(element.distArray.get(i).get(0)).from('cm').to('in');
          break;
        case Right:
          distRight = convert(element.distArray.get(i).get(0)).from('cm').to('in');
          break;
        case Back:
          distBack = convert(element.distArray.get(i).get(0)).from('cm').to('in');
          break;
        case Front:
          distFront = convert(element.distArray.get(i).get(0)).from('cm').to('in');
          break;
      }
    }
  } else {
    for(let i = 0; i < 4; i++){
      switch(element.distArray[i][1]) {
        case Left:
          distLeft = convert(element.distArray[i][0]).from('cm').to('in');
          break;
        case Right:
          distRight = convert(element.distArray[i][0]).from('cm').to('in');
          break;
        case Back:
          distBack = convert(element.distArray[i][0]).from('cm').to('in');
          break;
        case Front:
          distFront = convert(element.distArray[i][0]).from('cm').to('in');
          break;
      }
    }
  }

  const moveCabinet = () => {
    onUpdate('pos', { 'x': newX, 'y': newY });
  };

  const changeDistance = (oldVal, newVal, oldRot) => {
    let rotRad = (oldRot + renderedR) / 180 * Math.PI
    let dist;
    switch (oldRot) {
      case Left:
        dist = distRight;
        break;
      case Right:
        dist = distLeft;
        break;
      case Back:
        dist = distFront;
        break;
      case Front:
        dist = distBack;
        break;
      default:
        break;
    }
    newX = renderedX + convert((oldVal - newVal) * Math.cos(rotRad)).from('in').to('cm');
    newY = renderedY + convert((oldVal - newVal) * Math.sin(rotRad)).from('in').to('cm');
    if (dist + (oldVal - newVal) <= 0 || newVal < 0) {
      setPopupOpen(true);
      return;
    }
    onUpdate('pos', { 'x': newX, 'y': newY });
  };

  let handleConfirmLoad = value => {
    setPopupOpen(value);
  }



  const EditorOptions = () =>{

    return propertiesFormData.entrySeq().map(([propertyName, data]) => {
      if (catalog.categoryHasElement('Windows', element.type)) {
        if (['thickness', 'width', 'height'].includes(propertyName)) {
          return null;
        }
      }
      if (catalog.categoryHasElement('Doors', element.type)) {
        if (['altitude', 'thickness', 'width', 'height'].includes(propertyName)) {
          return null;
        }
      }
      if(filteredProperties){
        if(filteredProperties.includes(propertyName)) return null
      }

      let catalogElement = catalog.getElement(element.type);
      // if ((catalogElement.info.has_single_door === undefined || catalogElement.info.has_single_door == false) && propertyName == 'flip_doorhandle') {
      //   return null;
      // }

      let currentValue = data.get('currentValue'),
        configs = data.get('configs');
      let { Editor } = catalog.getPropertyType(configs.type);
      return ( configs.type === "length-measure" &&
        <div style={{position: 'relative'}} key={propertyName}>
          <Editor
            propertyName={propertyName}
            value={currentValue}
            configs={configs}
            onUpdate={value => onConfigUpdate(propertyName, value)}
            state={state}
            sourceElement={element}
            internalState={internalState}
          />
          <NumberInputMeasure>{unit}</NumberInputMeasure>
        </div>
      );
    })

  }

  if(shouldOnlyShowEditorOptions) return EditorOptions()

  let unit = state.getIn(['scene', 'layers', state.scene.selectedLayer, 'unit'])

  return (
    <div>
      <ConfirmPopup
        visible={isPopupOpen}
        handleConfirmLoad={handleConfirmLoad}
        ok={moveCabinet}
      />
      <NumberInputWrapper>
        <NumberInputTitle>Distance Left</NumberInputTitle>
        <FormNumberInput
          value={distLeft && convert(distLeft).from('in').to(unit)}
          disabled={distLeft ? false : true}
          onChange={event => changeDistance(distLeft, event.target.value, Left)}
          state={state}
          precision={2}
          style={{paddingRight: `${unit.length * 20}px`}}
          {...rest}
        />
        <NumberInputMeasure>{unit}</NumberInputMeasure>
      </NumberInputWrapper>
      <NumberInputWrapper>
        <NumberInputTitle>Distance Right</NumberInputTitle>
        <FormNumberInput
          disabled={distRight ? false : true}
          value={distRight && convert(distRight).from('in').to(unit)}
          onChange={event => changeDistance(distRight, event.target.value, Right)}
          state={state}
          style={{paddingRight: `${unit.length * 20}px`}}
          precision={2}
          {...rest}
        />
        <NumberInputMeasure>{unit}</NumberInputMeasure>
      </NumberInputWrapper>
      <NumberInputWrapper>
        <NumberInputTitle>Distance Front</NumberInputTitle>
        <FormNumberInput
          disabled={distFront ? false : true}
          value={distFront && convert(distFront).from('in').to(unit)}
          onChange={event => changeDistance(distFront, event.target.value, Front)}
          state={state}
          style={{paddingRight: `${unit.length * 20}px`}}
          precision={2}
          {...rest}
        />
        <NumberInputMeasure>{unit}</NumberInputMeasure>
      </NumberInputWrapper>
      <NumberInputWrapper>
        <NumberInputTitle>Distance Back</NumberInputTitle>
        <FormNumberInput
          disabled={distBack ? false : true}
          value={distBack && convert(distBack).from('in').to(unit)}
          onChange={event => changeDistance(distBack, event.target.value, Back)}
          state={state}
          style={{paddingRight: `${unit.length * 20}px`}}
          precision={2}
          {...rest}
        />
        <NumberInputMeasure>{unit}</NumberInputMeasure>
      </NumberInputWrapper>
      {EditorOptions()}
      <NumberInputWrapper>
        <NumberInputTitle>Rotation</NumberInputTitle>
        <FormNumberInput
          value={renderedR}
          onChange={event => onUpdate('rotation', event.target.value)}
          state={state}
          precision={2}
          {...rest}
        />
        <div style={{position: 'absolute', fontFamily: DEFAULT_FONT_FAMILY, fontSize: 19, right: 15, top: 15, border: `1px solid ${TEXT_COLOR_NEUTRAL_2}`, height: 5, width: 5, borderRadius: '50%'}} />
      </NumberInputWrapper>

    </div>
  );
}

ItemAttributesEditor.propTypes = {
  element: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  attributeFormData: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
};

ItemAttributesEditor.contextTypes = {
  translator: PropTypes.object.isRequired,
};
