import React, { useState } from 'react';

import * as S from './styles';
import * as MS from '../styles';
const STYLE_TITLE = {
  fontSize: '12px',
  color: '#fff',
  padding: '5px 7px 8px 8px',
  backgroundColor: '#3e3e3f',
  display: 'flex',
  height: '90px',
  justifyContent: 'start',
  alignItems: 'center'
};

export default function FloorProduct({ url, title, onClick, name }) {

  return (
      <S.FloorPlanContentWraper style={{display:'flex', alignItems: "center", cursor: "pointer"}} onClick={onClick}>
        <h3
          style={{...STYLE_TITLE}}
        >
          <img name={name} src={url} width={40} height={60}/>
        </h3>
        <div style={{...STYLE_TITLE}}>
          <div style={{width:"60px"}}>
            <span>{title}</span>
          </div>
          <S.Control
            style={{paddingLeft: 5}}
          >
            <S.ControlIcon key={name}>
              <img width={'60px'} name={name} src={'/assets/img/svg/toolbar/add_button.svg'} />
              <MS.ButtonTitle style={{top: "40%"}}>
                ADD
              </MS.ButtonTitle>
            </S.ControlIcon>
          </S.Control>
        </div>
      </S.FloorPlanContentWraper>
  )
}
