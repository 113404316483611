import styled from 'styled-components';
import {
  BG_COLOR_1,
  BG_COLOR_HOVER,
  DEFAULT_FONT_FAMILY,
  SECONDARY_PURPLE_COLOR,
  TEXT_COLOR_NEUTRAL_1,
  TEXT_COLOR_NEUTRAL_2,
  TEXT_COLOR_NEUTRAL_3,
  TEXT_COLOR_NEUTRAL_0
} from '../../../../constants';

export const DialogTitle = styled.span`
  color: ${TEXT_COLOR_NEUTRAL_1};
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  padding: 25px 30px;
`

export const DialogContent = styled.span`
  font-family: ${DEFAULT_FONT_FAMILY};
  color: ${TEXT_COLOR_NEUTRAL_1};
  padding: 10px 30px;
  
@media screen and (min-width : 1024){font-size : 11px;}
@media screen and (max-width : 1024){font-size : 11px;}
@media screen and (min-width : 1366){font-size : 13px;}
@media screen and (max-width : 1366){font-size : 13px;}
@media screen and (min-width : 1440){font-size : 16px;}
@media screen and (max-width : 1440){font-size : 16px;}
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  width: 100%;
`
export const DialogContents = styled.span`
  font-family: ${DEFAULT_FONT_FAMILY};
  color: ${TEXT_COLOR_NEUTRAL_1};
  padding: 10px 0px;
  
@media screen and (min-width : 1024){font-size : 11px;}
@media screen and (max-width : 1024){font-size : 11px;}
@media screen and (min-width : 1366){font-size : 13px;}
@media screen and (max-width : 1366){font-size : 13px;}
@media screen and (min-width : 1440){font-size : 16px;}
@media screen and (max-width : 1440){font-size : 16px;}
  font-weight: 400;
  line-height: 18px;
  text-align: left;
`

export const DialogContentSelection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  max-height: calc(100vh - 300px);
  overflow-y: scroll;
`

export const DialogAction = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px 30px 25px;
`

export const Button = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
  color: ${SECONDARY_PURPLE_COLOR};
  font-weight: bold;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 16px;
  border-radius: 20px;
  :hover{
    background-color: ${BG_COLOR_HOVER};
  }
`

export const DialogTextArea = styled.textarea`
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  padding: 10px;
  resize: none;
  height: 140px;
  width: 100%;
  outline: 0;
  border-radius: 5px;
  :hover{
    border: 2px solid ${SECONDARY_PURPLE_COLOR};
  }
  :focus{
    border: 2px solid ${SECONDARY_PURPLE_COLOR};
  }
`

export const StyledInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 0px;
`

export const PlusImage = styled.img`
  position: absolute;
  height: 25px;
  width: 25px;
  transform: rotate(45deg);
  right: 15px;
  cursor: pointer;
  background-color: ${TEXT_COLOR_NEUTRAL_3};
  -webkit-mask-image:url(${props=>props.maskImage});
  -webkit-mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
`

export const StyledInput = styled.input`
  width: 100%;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  height: 50px;
  text-align: left;
  padding: 10px;
  padding-right: 60px;
  color: ${TEXT_COLOR_NEUTRAL_2};
  border: 1px solid;
  border-radius: 5px;
  outline: 0;
  :focus{
    border: 2px solid ${SECONDARY_PURPLE_COLOR};
  }
  :hover{
    border: 2px solid ${SECONDARY_PURPLE_COLOR};
  }
`;

export const StyledInputLabel = styled.span`
  font-size: 16px;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  position: absolute;
  left: 47px;
  color: ${TEXT_COLOR_NEUTRAL_2};
  user-select: none;
`;

export const StyledInputHeadLabel = styled.span`
  font-size: 12px;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  position: absolute;
  left: 37px;
  top: -9px;
  background-color: ${BG_COLOR_1};
  padding: 0px 7px;
  color: ${SECONDARY_PURPLE_COLOR};
  user-select: none;
`;

export const StyledSelectLabel = styled.span`
  position: absolute;
  left: 8px;
  
@media screen and (min-width : 1024){font-size : 11px;}
@media screen and (max-width : 1024){font-size : 11px;}
@media screen and (min-width : 1366){font-size : 13px;}
@media screen and (max-width : 1366){font-size : 13px;}
@media screen and (min-width : 1440){font-size : 16px;}
@media screen and (max-width : 1440){font-size : 16px;}
  line-height: 18px;
  padding: 0px 8px;
  top: -9px;
  font-weight: 400;
  background-color: ${BG_COLOR_1};
  color: ${TEXT_COLOR_NEUTRAL_2};
  font-family: ${DEFAULT_FONT_FAMILY};
`

export const StyledSelect = styled.div`
  cursor: pointer;
  padding: 10px;
  color: ${TEXT_COLOR_NEUTRAL_1};
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  height: 35px;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  border: 2px solid ${TEXT_COLOR_NEUTRAL_3};
  :hover{
    border-color: ${SECONDARY_PURPLE_COLOR};
  }
`

export const StyledSelectOption = styled.div`
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: left;
  font-family: ${DEFAULT_FONT_FAMILY};
  cursor: pointer;
  :hover{
    background-color: ${BG_COLOR_HOVER};
  }
`

export const SelectArrow = styled.img`
  position: absolute;
  right: 15px;
  cursor: pointer;
  width: 15px;
  height: 9px;
  background-color: ${TEXT_COLOR_NEUTRAL_3};
  -webkit-mask-image:url(${props=>props.maskImage});
  -webkit-mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
`

export const StyledSelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 0px;
`

export const DoorCategoryImage = styled.img`
  width: 22px;
  height: 22px;
  margin-left: 5px;
  margin-right: 10px;
  background-color: ${SECONDARY_PURPLE_COLOR};
  -webkit-mask-image:url(${props=>props.maskImage});
  -webkit-mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
`

export const StyledCheck = styled.div`
  border: 1px solid;
  margin-right: 10px;
  color: ${TEXT_COLOR_NEUTRAL_2};
  height: 20px;
  border-radius: 50%;
  width: 20px;
  padding: 2px;
  cursor: pointer;
  background-size: 16px 16px;
  background-position: center;
  background-clip: content-box;
  :hover{
    color: ${SECONDARY_PURPLE_COLOR};
  }
`