import React, { Component } from 'react';
import PropTypes, { element, elementType } from 'prop-types';
import convert from 'convert-units';
import * as S from './button/styles';
import * as rectangleData from '../toolbar/main/rectangle.json';
import * as lShaped from '../toolbar/main/lShaped.json';
import * as oRectangle from '../toolbar/main/oRectangle.json';
import * as longNarrow from '../toolbar/main/longNarrow.json';
import {
  TEXT_COLOR_NEUTRAL_5,
  TEXT_COLOR_NEUTRAL_0,
  TEXT_COLOR_NEUTRAL_2,
  STATUS_NEGATIVE_COLOR,
  BG_COLOR_HOVER,
  BG_COLOR_1,
  TEXT_COLOR_NEUTRAL_3,
  SECONDARY_PURPLE_COLOR,
  DEFAULT_FONT_FAMILY,
  SHADE_LIGHT_PURPLE_COLOR,
  TEXT_COLOR_NEUTRAL_1,
  TEXT_COLOR_NEUTRAL_4,
} from '../../constants';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm{
    border-radius: 10px;
  }
`;

let roomShapeData = [
  {
    type: 'square',
    name: 'Square',
    url: '/assets/img/svg/wizardstep/Square.svg',
    data: rectangleData,
    key : 1
  },
  {
    type: 'l-shape',
    name: 'L-Shaped',
    url: '/assets/img/svg/wizardstep/L.svg',
    data: lShaped,
    key : 2
  },
  {
    type: 'irregular',
    name: 'Irregular',
    url: '/assets/img/svg/wizardstep/L2.svg',
    data: longNarrow,
    key : 3
  },
  {
    type: 'open',
    name: 'Open',
    url: '/assets/img/svg/wizardstep/Open.svg',
    data: oRectangle,
    key : 4
  }
];

let colorData = [
  {
    name: 'White',
    color: '#ffffff',
    url: '/assets/img/svg/color/White.svg'
  },
  {
    name: 'Cream',
    color: '#f4efe4',
    url: '/assets/img/svg/color/Cream.svg'
  },
  {
    name: 'Gray',
    color: '#bec1c3',
    url: '/assets/img/svg/color/Gray.svg'
  },
  {
    name: 'Blue',
    color: '#4d83a6',
    url: '/assets/img/svg/color/Blue.svg'
  },
  {
    name: 'Green',
    color: '#88aa7f',
    url: '/assets/img/svg/color/Green.svg'
  },
  {
    name: 'Black',
    color: '#414142',
    url: '/assets/img/svg/color/Black.svg'
  },
  {
    name: 'Unfinished',
    color: '',
    url: '/assets/img/svg/color/Unfinished.svg'
  },
  {
    name: 'Light',
    color: '',
    url: '/assets/img/svg/color/Light.svg'
  },
  {
    name: 'Medium',
    color: '',
    url: '/assets/img/svg/color/Medium.svg'
  },
  {
    name: 'Dark',
    color: '',
    url: '/assets/img/svg/color/Dark.svg'
  },
  {
    name: 'Brown',
    color: '',
    url: '/assets/img/svg/color/Brown.svg'
  },
];

let unitData = [
  {
    _name: 'inch',
    _unit: 'in'
  },
  {
    _name: 'centimeter',
    _unit: 'cm'
  },
  {
    _name: 'millimeter',
    _unit: 'mm'
  },
  {
    _name: 'meter',
    _unit: 'm'
  },
  {
    _name: 'feet',
    _unit: 'ft'
  }
];

export default class WizardStep extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      step: 0,
      measurementUnit: {
        _name: 'inch',
        _unit: 'in'
      },
      ceilingHeight: 96,
      roomShape: {
        type: '',
        data: {}
      },
      colorFamily: {},
      installation: this.props.categoryData.data.doorStyles.items.filter(item => item.items.filter(item1 => item1.items.length !== 0).length !== 0)[0],
      doorCategory: this.props.categoryData.data.doorStyles.items.filter(item => item.items.filter(item1 => item1.items.length !== 0).length !== 0)[0].items.filter(item => item.items.length !== 0)[0],
      doorStyle: this.props.categoryData.data.doorStyles.items.filter(item => item.items.filter(item1 => item1.items.length !== 0).length !== 0)[0].items.filter(item => item.items.length !== 0)[0].items[0],
      cancelDialogVisible: false,
      hover: 0
    };
  }

  loadProject(data) {
    this.context.projectActions.loadProject(data);
  }

  _confirm(type) {
    this.props.close();
    if (this.state.roomShape.type === 'custom')
      this.context.linesActions.selectToolDrawingLine('wall');
    else if (this.state.roomShape.type === '') {
    } else this.loadProject(this.state.roomShape.data);
    this.context.viewer2DActions.updateCeilHeight(this.state.ceilingHeight);
    this.context.viewer2DActions.updateCeilHeightUnit(this.state.measurementUnit._unit);
    this.context.viewer3DActions.update3DCeilHeight(this.state.ceilingHeight);
    this.context.viewer3DActions.update3DCeilHeightUnit(this.state.measurementUnit._unit);
    !!type && this.context.itemsActions.setDoorStyle(this.state.doorStyle);
  }

  _ok() {
    if(this.state.step === 1 && this.state.roomShape.type === ''){
      roomShapeData.map(item => {
        document.getElementById(`roomshape&${item.type}`).style.backgroundColor = STATUS_NEGATIVE_COLOR;
        document.getElementById(`roomlabel&${item.type}`).style.color = TEXT_COLOR_NEUTRAL_0;
      })
      document.getElementById('roomshape&custom').style.backgroundColor = STATUS_NEGATIVE_COLOR;
      document.getElementById('roomshape&pencil').style.backgroundColor = STATUS_NEGATIVE_COLOR;
      document.getElementById('roomlabel&custom').style.color = TEXT_COLOR_NEUTRAL_0;
      document.getElementById('description').style.color = STATUS_NEGATIVE_COLOR;
    }
    else this.setState({step: this.state.step + 1});
  }

  onStepChange(e) {
    if(this.state.step === 1 && this.state.roomShape.type === ''){
      roomShapeData.map(item => {
        document.getElementById(`roomshape&${item.type}`).style.backgroundColor = STATUS_NEGATIVE_COLOR;
        document.getElementById(`roomlabel&${item.type}`).style.color = TEXT_COLOR_NEUTRAL_0;
      })
      document.getElementById('roomshape&custom').style.backgroundColor = STATUS_NEGATIVE_COLOR;
      document.getElementById('roomshape&pencil').style.backgroundColor = STATUS_NEGATIVE_COLOR;
      document.getElementById('roomlabel&custom').style.color = TEXT_COLOR_NEUTRAL_0;
      document.getElementById('description').style.color = STATUS_NEGATIVE_COLOR;
    } else{
      this.setState({step: e});
    }
  }

  render() {
    const { step, roomShape, installation, doorCategory, doorStyle, measurementUnit, ceilingHeight, cancelDialogVisible, colorFamily, hover } = this.state;
    let regexp = new RegExp(`^-?([0-9]+)?\\.?([0-9]{0,2})?$`);

    // console.log(this.props.categoryData.data.doorStyles.items.filter(item => item.items.filter(item1 => item1.items.length !== 0).length !== 0)[0].items.filter(item => item.items.length !== 0)[0].items[0]);

    const stepBar = [
      {
        index: 1,
        title: 'ROOM SHAPE',
        description: (<div>Select units, add ceiling height and a predefined shape or draw  your own.
          <br/>
          To get professional help, click on the Help button.
        </div>),
        content: (
          <S.ContentWrapper>
            <S.ContentWrapper1 style={{ marginBottom: 25 }}>
              <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                <S.MeasurementUnitLabel>Measurement Unit</S.MeasurementUnitLabel>
                <S.MeasurementUnitSelect
                  id="measurement_select"
                  onClick={() => {
                    document.getElementById('measurement_option').style.display = document.getElementById('measurement_option').style.display === 'none' ? 'block' : 'none';
                    document.getElementById( 'measurement_select').style.borderColor = SECONDARY_PURPLE_COLOR;
                    document.getElementById('arrow-down').style.display = document.getElementById('arrow-down').style.display === 'none' ? 'block' : 'none';
                    document.getElementById('arrow-up').style.display = document.getElementById('arrow-up').style.display === 'block' ? 'none' : 'block';
                  }}
                >
                  {`${measurementUnit._name}(${measurementUnit._unit})`}
                </S.MeasurementUnitSelect>
                <S.SelectArrow id="arrow-down" style={{ display: 'block' }} maskImage="/assets/img/svg/bottombar/arrow-down.svg"/>
                <S.SelectArrow id="arrow-up" style={{ display: 'none' }} maskImage="/assets/img/svg/bottombar/arrow-up.svg"/>
                <div id="measurement_option" style={{ display: 'none', width: '100%', zIndex: 3, top: 55, position: 'absolute', borderRadius: 5, backgroundColor: 'white', boxShadow: '0px 3px 5px -1px rgba(156, 154, 154, 0.2), 0px 6px 10px 0px rgba(156, 154, 154, 0.39), 0px 1px 18px 0px rgba(156, 154, 154, 0.12)' }}>
                  {unitData.map((unit, key) => {
                    return (
                      <S.MeasurementUnitOption
                        onClick={() => {
                          document.getElementById('measurement_option').style.display = 'none';
                          document.getElementById('arrow-down').style.display = 'block';
                          document.getElementById('arrow-up').style.display = 'none';
                          this.setState({ ceilingHeight: convert(ceilingHeight).from(measurementUnit._unit).to(unit._unit), measurementUnit: unit });
                        }}
                        key={key}
                      >
                        {`${unit._name}(${unit._unit})`}
                      </S.MeasurementUnitOption>
                    );
                  })}
                </div>
              </div>
              <div style={{ position: 'relative', display: 'flex', alignItems: 'center', marginLeft: 40 }}>
                <S.MeasurementUnitLabel>Ceiling Height</S.MeasurementUnitLabel>
                <S.CeilingHeight
                  value={ceilingHeight}
                  type="text"
                  onChange={evt => {
                    if(regexp.test(evt.target.value)) this.setState({ ceilingHeight: evt.target.value });
                    else this.setState({ceilingHeight: ceilingHeight});
                  }}
                />
                <span style={{ position: 'absolute', color: TEXT_COLOR_NEUTRAL_1, fontSize: 16, fontFamily: DEFAULT_FONT_FAMILY, fontWeight: 400, lineHeight: '22px', textAlign: 'left', left: 55, }}>{measurementUnit._unit === 'in' ? `${measurementUnit._unit}(default)` : measurementUnit._unit}</span>
              </div>
            </S.ContentWrapper1>
            <S.ContentWrapper1>
              {roomShapeData.map((item, key) => {
                return (
                  <S.ContentItem
                    onClick={() => {
                      this.setState({ roomShape: item });
                      document.getElementById('description').style.color = TEXT_COLOR_NEUTRAL_0;
                    }}
                    key={item.key}
                  >
                    <S.MaskImageWrapper style={{ backgroundColor:roomShape.type === item.type && BG_COLOR_HOVER }}>
                      <S.RoomContentImage id={`roomshape&${item.type}`} maskImage={item.url} style={{backgroundColor: roomShape.type === item.type ? SECONDARY_PURPLE_COLOR : roomShape.type ? TEXT_COLOR_NEUTRAL_3 : TEXT_COLOR_NEUTRAL_0}}/>
                    </S.MaskImageWrapper>
                    <S.ContentCheckImage id="check" src="/assets/img/svg/wizardstep/check-normal.svg"/>
                    {roomShape.type === item.type && <S.ContentCheckImage src="/assets/img/svg/wizardstep/check-active.svg" />}
                    <S.ContentItemLabel id={`roomlabel&${item.type}`} style={{ fontWeight: roomShape.type === item.type && 400, color: roomShape.type === item.type ? SECONDARY_PURPLE_COLOR : roomShape.type ? TEXT_COLOR_NEUTRAL_3 : TEXT_COLOR_NEUTRAL_0, }}>{item.name}</S.ContentItemLabel>
                  </S.ContentItem>
                );
              })}
            </S.ContentWrapper1>
            <S.ContentWrapper1>
              <S.ContentItem
                onClick={() => {
                  this.setState({ roomShape: {type: 'custom', data: {}} });
                  document.getElementById('description').style.color = TEXT_COLOR_NEUTRAL_0;
                }}
              >
                <S.MaskImageWrapper style={{ backgroundColor: roomShape.type === 'custom' && BG_COLOR_HOVER }} >
                  <S.RoomContentImage id="roomshape&custom" maskImage="/assets/img/svg/wizardstep/Custom.svg" style={{backgroundColor: roomShape.type === 'custom' ? SECONDARY_PURPLE_COLOR : roomShape.type ? TEXT_COLOR_NEUTRAL_3 : TEXT_COLOR_NEUTRAL_0}} />
                  <S.RoomContentImage id="roomshape&pencil" maskImage="/assets/img/svg/wizardstep/pencil.svg" style={{ position: 'absolute', top: 10, right: 10, height: 25, width: 25, backgroundColor: roomShape.type === 'custom' ? SECONDARY_PURPLE_COLOR : roomShape.type ? TEXT_COLOR_NEUTRAL_3 : TEXT_COLOR_NEUTRAL_0 }}/>
                </S.MaskImageWrapper>
                <S.ContentCheckImage id="check" src="/assets/img/svg/wizardstep/check-normal.svg" />
                {roomShape.type === 'custom' && <S.ContentCheckImage src="/assets/img/svg/wizardstep/check-active.svg" />}
                <S.ContentItemLabel id="roomlabel&custom" style={{ fontWeight: roomShape.type === 'custom' && 600, color: roomShape.type === 'custom' ? SECONDARY_PURPLE_COLOR : roomShape.type ? TEXT_COLOR_NEUTRAL_3 : TEXT_COLOR_NEUTRAL_0 }}>Draw Custom</S.ContentItemLabel>
                <span id='hint' style={{ position: 'absolute', fontFamily: DEFAULT_FONT_FAMILY, fontSize: 16, lineHeight: '20px', textAlign: 'left', backgroundColor: TEXT_COLOR_NEUTRAL_1, opacity: 0.8, color: BG_COLOR_1, borderRadius: 3, zIndex: 3, width: 'max-content', top: -30, left: -10, padding: '3px 8px', }}>You will be able to draw your own space</span>
              </S.ContentItem>
            </S.ContentWrapper1>
          </S.ContentWrapper>
        )
      },
      {
        index: 2,
        title: 'COLOR & STYLE',
        description: 'Customize your door style. To get professional help, click on the Help button.',
        content: (
          <S.ContentWrapper>
            <S.Title>Installation</S.Title>
            <S.ContentWrapper1>
              {this.props.categoryData.data.doorStyles.items.map((item, key) => {
                return (
                  <S.DoorStyleContentItem onClick={() => this.setState({installation: item, doorCategory: item.items[0]})} key={key}>
                    <S.DoorStyleContentImageWrapper id='item' style={{ backgroundColor: installation.name === item.name && TEXT_COLOR_NEUTRAL_5, borderColor: installation.name === item.name && SECONDARY_PURPLE_COLOR }}>
                      <S.DoorStyleContentImage id={`doorstyle&${item.name}&image`} maskImage={item.thumbnail} style={{backgroundColor : installation.name === item.name && SECONDARY_PURPLE_COLOR}} />
                    </S.DoorStyleContentImageWrapper>
                    <S.ContentItemLabel style={{ color: installation.name === item.name && SECONDARY_PURPLE_COLOR, fontSize: 15, fontWeight: installation.name === item.name && 700 }}>{item.name}</S.ContentItemLabel>
                  </S.DoorStyleContentItem>
                );
              })}
            </S.ContentWrapper1>
            <S.Title style={{ marginTop: 20}}>Door Style</S.Title>
            <S.ContentWrapper1>
              {this.props.categoryData.data.doorStyles.items.filter(item => item.name === installation.name)[0].items.map((element, key) => {
                return (
                  <S.DoorStyleContentItem onClick={() => this.setState({doorCategory: element})} key={key}>
                    <S.DoorStyleContentImageWrapper id="item" style={{ backgroundColor: doorCategory.name === element.name && TEXT_COLOR_NEUTRAL_5, borderColor: doorCategory.name === element.name && SECONDARY_PURPLE_COLOR }}>
                      <S.DoorCategoryContentImage style={{ margin: doorCategory.name === element.name && 'unset', backgroundColor: doorCategory.name === element.name && SECONDARY_PURPLE_COLOR }} maskImage={element.thumbnail}/>
                    </S.DoorStyleContentImageWrapper>
                    <S.ContentItemLabel id={`doorcategory&${element.name}&label`} style={{ color: doorCategory.name === element.name ? SECONDARY_PURPLE_COLOR : TEXT_COLOR_NEUTRAL_0, fontSize: 15, fontWeight: doorCategory.name === element.name && 700 }}>{element.name}</S.ContentItemLabel>
                  </S.DoorStyleContentItem>
                );
              })}
            </S.ContentWrapper1>
            <S.Title style={{ marginTop: 20}}>Color family</S.Title>
            <S.ContentWrapper1>
              {this.props.categoryData.data.doorColorFamily && this.props.categoryData.data.doorColorFamily.map((element, key) => {
                return (
                  <S.ColorContentItem onClick={() => this.setState({ colorFamily: element }) } key={key} >
                    <S.ColorContentImage src={element.thumbnail} />
                    <S.ContentCheckImage id="check" src="/assets/img/svg/wizardstep/check-normal.svg" style={{height: 70, width: 70, borderRadius: "50%", backgroundColor: 'unset', zIndex: 2, padding: 16}}/>
                    {colorFamily.id === element.id && (<S.ContentCheckImage src="/assets/img/svg/wizardstep/check-active.svg" style={{height: 70, width: 70, borderRadius: "50%", backgroundColor: 'unset', zIndex: 2, padding: 16, border: `2px solid ${SECONDARY_PURPLE_COLOR}`}} />)}
                  </S.ColorContentItem>
                );
              })}
            </S.ContentWrapper1>
          </S.ContentWrapper>
        )
      },
      {
        index: 3,
        title: 'SUMMARY',
        description: (<div><div>Select from the door collection for all cabinets.</div><div>To get professional help, click on the Help button.</div></div>),
        content: (
          <S.ContentWrapper>
            <S.ContentWrapper1>
              <S.ContentWrapper2>
                <S.Title>Room Shape</S.Title>
                {roomShapeData.map((item, key) => {
                  return roomShape.type === item.type &&  (
                    <S.ContentItem style={{margin: 'unset', marginTop: 20 }} key={key}>
                      <S.MaskImageWrapper>
                        <S.RoomContentImage maskImage={item.url} style={{backgroundColor: SECONDARY_PURPLE_COLOR}}/>
                      </S.MaskImageWrapper>
                      <S.ContentCheckImage src="/assets/img/svg/wizardstep/check-active.svg" />
                      <S.ContentItemLabel style={{color: SECONDARY_PURPLE_COLOR}}>{item.name}</S.ContentItemLabel>
                    </S.ContentItem>
                  );
                })}
                {roomShape.type === 'custom' && <S.ContentItem style={{margin: 'unset', marginTop: 20 }}>
                  <S.MaskImageWrapper>
                    <S.RoomContentImage maskImage="/assets/img/svg/wizardstep/Custom.svg" style={{backgroundColor: SECONDARY_PURPLE_COLOR}}/>
                    <S.RoomContentImage maskImage="/assets/img/svg/wizardstep/pencil.svg" style={{ position: 'absolute', top: 10, right: 25, height: 25, width: 25, backgroundColor: SECONDARY_PURPLE_COLOR }}/>
                  </S.MaskImageWrapper>
                  <S.ContentCheckImage src="/assets/img/svg/wizardstep/check-active.svg" />
                  <S.ContentItemLabel style={{color: SECONDARY_PURPLE_COLOR}}>Draw Custom</S.ContentItemLabel>
                  <span id='hint' style={{ position: 'absolute', fontFamily: DEFAULT_FONT_FAMILY, fontSize: 16, lineHeight: '20px', textAlign: 'left', backgroundColor: TEXT_COLOR_NEUTRAL_1, opacity: 0.8, color: BG_COLOR_1, borderRadius: 3, zIndex: 3, width: 'max-content', top: -30, left: -10, padding: '3px 8px', }}>You will be able to draw your own space</span>
                </S.ContentItem>}
              </S.ContentWrapper2>
              <S.ContentWrapper2>
                <S.Title>Installation</S.Title>
                <S.DoorStyleContentItem style={{margin: 'unset', marginTop: 20}}>
                  <S.DoorStyleContentImageWrapper style={{borderColor: SECONDARY_PURPLE_COLOR, backgroundColor: TEXT_COLOR_NEUTRAL_5}}>
                    <S.DoorStyleContentImage maskImage={installation.thumbnail} style={{backgroundColor: SECONDARY_PURPLE_COLOR}} />
                  </S.DoorStyleContentImageWrapper>
                  <S.ContentItemLabel style={{color: SECONDARY_PURPLE_COLOR}}>{installation.name}</S.ContentItemLabel>
                </S.DoorStyleContentItem>
              </S.ContentWrapper2>
              <S.ContentWrapper2>
                <S.Title>Color Family</S.Title>
                <S.ColorContentItem style={{margin: 'unset', marginTop: 20}}>
                  {colorFamily.thumbnail && <S.ColorContentImage src={colorFamily.thumbnail} style={{height: 120, width: 120 , borderRadius : "100%" }} />}
                  <S.ContentCheckImage src="/assets/img/svg/wizardstep/check-active.svg" style={{height: 120, width: 120, borderRadius: "50%", backgroundColor: 'unset', zIndex: 2, padding: 41, border: `2px solid ${SECONDARY_PURPLE_COLOR}`}} />
                  <S.ContentItemLabel style={{color: SECONDARY_PURPLE_COLOR}}>{colorFamily.name}</S.ContentItemLabel>
                </S.ColorContentItem>
              </S.ContentWrapper2>
              <S.ContentWrapper2>
                <S.Title>Style</S.Title>
                <S.DoorStyleContentItem style={{margin: 'unset', marginTop: 20}}>
                  <S.DoorStyleContentImageWrapper style={{ backgroundColor: TEXT_COLOR_NEUTRAL_5, borderColor: SECONDARY_PURPLE_COLOR }}>
                    <S.DoorCategoryContentImage style={{ margin: 'unset', backgroundColor: SECONDARY_PURPLE_COLOR }} maskImage={doorCategory.thumbnail}/>
                  </S.DoorStyleContentImageWrapper>
                  <S.ContentItemLabel style={{ color: SECONDARY_PURPLE_COLOR}}>{doorCategory.name}</S.ContentItemLabel>
                </S.DoorStyleContentItem>
              </S.ContentWrapper2>
              <S.ContentWrapper2>
                <S.Title>Finish</S.Title>
                <S.DoorStyleContentItem style={{margin: 'unset', marginTop: 20}}>
                  <S.FinishContentImageWrapper style={{border: `2px solid ${SECONDARY_PURPLE_COLOR}`, backgroundColor: 'unset',minHeight:120,  height: 120, width: 120}}>
                    <S.FinishContentImage src={doorStyle.thumbnail} style={{height: "70%", width:"100%", objectFit: "contain"}}/>
                  </S.FinishContentImageWrapper>
                  <S.ContentItemLabel style={{ color: SECONDARY_PURPLE_COLOR, marginBottom: 15, width: 120 }}>{doorStyle.name}</S.ContentItemLabel>
                </S.DoorStyleContentItem>
              </S.ContentWrapper2>
            </S.ContentWrapper1>
          </S.ContentWrapper>
        )
      }
    ];

    return (
      <div>
      {
        step === 0 ? (
          <S.WizardIntro>
            <S.IntroPlane>
              <S.IntroMark>
                <img src='/assets/img/loading/loading_1.svg' width={80}></img>
              </S.IntroMark>
              <S.IntroTitle>
                Welcome to DIY Design Space
              </S.IntroTitle>
              <S.IntroDescription>
                Let’s start designing your kitchen. Select the type of project you want to start.
              </S.IntroDescription>
              <S.IntroSelect>
                <S.IntroItem onClick={()=>{this.setState({step: 1})}} >
                  <img src={`/assets/img/svg/intro/1-start-with-floorplan${hover === 1 ? '-whole' : ''}.svg`} onMouseOut={()=>{this.setState({hover: 0})}} onMouseOver={()=>{this.setState({hover: 1})}} width={140}/>
                  <S.IntroItemText>
                    Start with Floorplan
                  </S.IntroItemText>
                </S.IntroItem>
                <S.IntroItem onClick={() => {
                  this.props.close();
                  this.context.viewer2DActions.updateCeilHeight(96);
                  this.context.viewer2DActions.updateCeilHeightUnit('in');
                  this.context.viewer3DActions.update3DCeilHeight(96);
                  this.context.viewer3DActions.update3DCeilHeightUnit('in');
                }}>
                  <img src={`/assets/img/svg/intro/2-start-from-scratch${hover === 2 ? '-whole' : ''}.svg`} onMouseOut={()=>{this.setState({hover: 0})}} onMouseOver={()=>{this.setState({hover: 2})}} width={140}/>
                  <S.IntroItemText>
                    Start from Scratch
                  </S.IntroItemText>
                </S.IntroItem>
                {/* <S.IntroItem>
                  <img src={`/assets/img/svg/intro/3-retrieve-project${hover === 3 ? '-whole' : ''}.svg`} onMouseOut={()=>{this.setState({hover: 0})}} onMouseOver={()=>{this.setState({hover: 3})}} width={140}/>
                  <S.IntroItemText>
                    Retrieve a saved project
                  </S.IntroItemText>
                </S.IntroItem> */}
              </S.IntroSelect>
            </S.IntroPlane>
          </S.WizardIntro>
        ) : (
          <S.WizardStepWrapper>
            <S.TitleBarWrapper>
              <S.TitleBar>
                {step === 1 ? 'Choose your room shape' : step === 3 ? 'Summary' : 'Choose your door style'}
              </S.TitleBar>
              <S.TitleBarButtonWrapper>
                <S.TitleBarButton style={{border: `3px solid ${BG_COLOR_1}`, cursor: step === 1 && 'no-drop', backgroundColor: step === 1 && BG_COLOR_1, color: step === 1 && TEXT_COLOR_NEUTRAL_4 }} onClick={() => step !== 1 && this.setState({cancelDialogVisible: true})}>Cancel</S.TitleBarButton>
                {step > 1 && <S.TitleBarButton
                  onClick={() => {
                    this.setState({ step: step - 1 });
                    // switch (step) {
                    //   case 5:
                    //     this.setState({ doorStyle: '' });
                    //     break;
                    //   case 4:
                    //     this.setState({ doorCategory: { name: '', url: '' }});
                    //     break;
                    //   case 3:
                    //     this.setState({ installation: '', doorCategory: { name: '', url: '' } });
                    //     break;
                    //   case 2:
                    //     this.setState({ installation: '' });
                    //     this.setState({ doorColor: { name: '', url: '', color: '' } });
                    //   default:
                    //     break;
                    // }
                  }}
                >
                  <img style={{ paddingRight: 10 }} height="10px" src="/assets/img/svg/toolbar/undo_button.svg"/>
                  Back
                </S.TitleBarButton>}
                <S.ConfirmButton style={{ minWidth:89 }} onClick={() => step < 3 ? this._ok() : this._confirm(1)}>
                  <img style={{ marginRight: 10, height: 18, width: 18, WebkitMaskImage: 'url(/assets/img/svg/headerbar/check.svg)',backgroundColor: BG_COLOR_1, WebkitMaskSize: '100%', WebkitMaskPosition: 'center' }}/>
                  {step > 2 ? 'Confirm' : 'OK'}
                </S.ConfirmButton>
              </S.TitleBarButtonWrapper>
            </S.TitleBarWrapper>
            <div style={{display: 'flex'}}>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <S.StepBarWrapper>
                  {stepBar.map((element, key) => {
                    switch (element.index) {
                      case 1:
                        return (
                          <S.StepBullet key={key}>
                            <S.StepBulletLabel style={{ color: step === element.index && SHADE_LIGHT_PURPLE_COLOR, fontWeight: step >= element.index && 700 }}>{element.title}</S.StepBulletLabel>
                            <S.StepBulletIcon src={step === element.index ? '/assets/img/svg/wizardstep/bullet-current.svg' : '/assets/img/svg/wizardstep/bullet-done.svg'} onClick={() => this.setState({step: element.index})}/>
                          </S.StepBullet>
                        );
                      default:
                        return (
                          <S.StepBullet key={key}>
                            <S.StepBulletLabel style={{ color: step === element.index && SHADE_LIGHT_PURPLE_COLOR, fontWeight: step >= element.index && 700 }}>{element.title}</S.StepBulletLabel>
                            <div style={{display: 'flex', position: 'relative', width: '100%', right: 'calc((100% - 10px) / 2)'}}>
                              <S.StepBarIcon src={step >= element.index - 1 ? '/assets/img/svg/wizardstep/bar-active.svg' : '/assets/img/svg/wizardstep/bar-normal.svg'}/>
                              <S.StepBulletIcon src={step === element.index ? '/assets/img/svg/wizardstep/bullet-current.svg' : step > element.index ? '/assets/img/svg/wizardstep/bullet-done.svg' : '/assets/img/svg/wizardstep/bullet-not-done.svg'} onClick={()=>this.onStepChange(element.index)}/>
                            </div>
                          </S.StepBullet>
                        );
                    }
                  })}
                </S.StepBarWrapper>
                <div>
                  <S.Description>
                    <span id="description">{stepBar[step - 1].description}</span>
                  </S.Description>
                </div>
                {stepBar[step - 1].content}
              </div>
              {step === 2 && <div style={{display: 'flex', flexDirection: 'column', marginLeft: 20, padding: 25, width: '100vw'}}>
                <S.DoorStyleWrapper>
                  {this.props.categoryData.data.doorStyles.items.filter(item => item.name === installation.name)[0].items.filter(item1 => item1.name === doorCategory.name)[0].items.length === 0 ? 
                    <span style={{fontFamily: DEFAULT_FONT_FAMILY, fontSize: 22, lineHeight: '28px', fontWeight: 700, margin: 'auto', marginTop: 50, color: STATUS_NEGATIVE_COLOR}}>There is no door style with current dealer</span> :
                    colorFamily.id && this.props.categoryData.data.doorStyles.items.filter(item => item.name === installation.name)[0].items.filter(item1 => item1.name === doorCategory.name)[0].items.filter(element => element.color_family.search(`,${colorFamily.id},`) === 0).length === 0 ?
                      <span style={{fontFamily: DEFAULT_FONT_FAMILY, fontSize: 22, lineHeight: '28px', fontWeight: 700, margin: 'auto', marginTop: 50, color: STATUS_NEGATIVE_COLOR}}>There is no door style with current door color family</span> :
                      this.props.categoryData.data.doorStyles.items.filter(item => item.name === installation.name)[0].items.filter(item1 => item1.name === doorCategory.name)[0].items.map((element, key) => {
                        return ( (!colorFamily.id || element.color_family.search(`,${colorFamily.id},`) === 0) ?
                          <S.DoorStyleContentItem style={{margin: 0, height: 340, width: 160 ,backgroundColor: doorStyle.name === element.name && "#EDF1FB", borderRadius: 6}} onClick={() => this.setState({ doorStyle: element })} key={key}>
                            <S.FinishContentImageWrapper id={`door&${element.name}&item`} style={{marginTop: 15, border: doorStyle.name === element.name && `2px solid ${SECONDARY_PURPLE_COLOR}`, backgroundColor: doorStyle.name === element.name && TEXT_COLOR_NEUTRAL_5}}>
                              <S.FinishContentImage  src={element.thumbnail} style={{ width: "100%", height: "100%" }} />
                              <S.ContentCheckImage id="check" src="/assets/img/svg/wizardstep/check-normal.svg" style={{height: 160, width: 120, borderRadius: 10, backgroundColor: 'unset', zIndex: 2}}/>
                              {doorStyle.name === element.name && (<S.ContentCheckImage src="/assets/img/svg/wizardstep/check-active.svg" style={{height: 160, width: 120, borderRadius: 10, backgroundColor: 'unset'}} />)}
                            </S.FinishContentImageWrapper>
                            <S.ContentItemLabel id={`door&${element.name}&label`} style={{fontWeight: doorStyle.name === element.name && 700, color: doorStyle.name === element.name ? SECONDARY_PURPLE_COLOR : TEXT_COLOR_NEUTRAL_0, maxWidth: "130px" }}>{element.name}</S.ContentItemLabel>
                          </S.DoorStyleContentItem> : null
                        )
                      })
                  }
                </S.DoorStyleWrapper>
              </div>}
            </div>
            <StyledDialog open={cancelDialogVisible} onClose={() => this.setState({cancelDialogVisible: false})}>
              <S.DialogContent>Do you want to apply your current selections or start from scratch?</S.DialogContent>
              <S.DialogAction>
                <S.Button
                onClick={() => {
                  this.props.close();
                  this.context.viewer2DActions.updateCeilHeight(96);
                  this.context.viewer2DActions.updateCeilHeightUnit('in');
                  this.context.viewer3DActions.update3DCeilHeight(96);
                  this.context.viewer3DActions.update3DCeilHeightUnit('in');
                }}
              >
                  Discard
                </S.Button>
                <S.Button onClick={() => this._confirm(0)}>
                  Apply
                </S.Button>
              </S.DialogAction>
            </StyledDialog>
          </S.WizardStepWrapper>
        )
      }
      </div>
    );
  }
}

WizardStep.propTypes = {
  state: PropTypes.object.isRequired,
  content: PropTypes.number.isRequired
};

WizardStep.contextTypes = {
  projectActions: PropTypes.object.isRequired,
  viewer2DActions: PropTypes.object.isRequired,
  viewer3DActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired
};
