import React, {Component} from 'react'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom';
import RegisterForm from './RegisterForm'
import history from '../../@history';
import './style.scss'

class Register extends Component {
    constructor(props) {
      super(props);
      this.state = {
          backgroundImage: 'url(/assets/img/1.jpg)',
          fullSize: false,
      }
    }

    generateBackground() {
      let { backgroundImage } = this.state
  
      let min = 1
      let max = 2
      let picNumber = Math.floor(Math.random() * (max - min + 1)) + min
      backgroundImage = 'url(demo/src/assets/img/' + picNumber + '.jpg)'
      this.setState({
        backgroundImage: backgroundImage,
      })
    }
    render() {
      const { backgroundImage} = this.state;
      let { state: historyState } = history.location;
      if (historyState === undefined) historyState = {type: 'register'};
      const { type } = historyState;

      return (
        <div
          className={'login login--fullscreen'}
          style={{ backgroundImage: backgroundImage }}
        >
          <div className="login__header">
            <div className="row">
              <div className="col-lg-8">
                <div className="login__header__logo">
                  {/* <Button className="ml-3" onClick={this.generateBackground}>
                    Randomize Background Image
                  </Button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="login__block">
            <div className="row">
              <div className="col-xl-12">
                <div className="login__block__promo text-white text-center">
                  <h1 className="mb-3 text-white">
                    <strong>WELCOME TO KITCHEN-CONFIGURATOR</strong>
                  </h1>
                </div>
                <div className="login__block__inner">
                  <div className="register__block__form">
                    <h4 className="text-uppercase text-center" style={{paddingTop: 10}}>
                      <strong>
                        Please {type === 'register' ? 'Register' : 'Add User'}
                      </strong>
                    </h4>
                    <RegisterForm type={type}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
}

function mapStateToProps(state)
{
    return { }
}

export default connect(mapStateToProps)(Register);
