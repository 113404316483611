import React from 'react';
import PropTypes from 'prop-types';
import convert from 'convert-units';
import IDBroker from '../../utils/id-broker';
import { FormNumberInput2 } from '../style/export';
import { SHADE_LIGHT_BLUE_COLOR } from '../../constants';

const STYLE = {
  stroke: SHADE_LIGHT_BLUE_COLOR,
  strokeWidth: "1px"
};

const BACKGROUNDSTYLE = {
  fill:'white',
  fillOpacity:0.800000
};

const STYLE_TEXT = {
  textAnchor: "middle",
  fontSize: "12px",  
  pointerEvents: "none",

  //http://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting-using-css
  WebkitTouchCallout: "none", /* iOS Safari */
  WebkitUserSelect: "none",   /* Chrome/Safari/Opera */
  MozUserSelect: "none",      /* Firefox */
  MsUserSelect: "none",       /* Internet Explorer/Edge */
  userSelect: "none"
};


export default function Ruler({ layer, length, unit, rulerUnit, transform }) {

  let update = (lengthInput, unitInput, type) => {
    let newLength = toFixedFloat(lengthInput);
    let merged = null;
    if(type === 0) {
      merged = value.merge({
        _length: newLength,
        length: convert(newLength).from(unitInput).to('cm'),
      });
    } else {
      merged = value.merge({
        _length: convert(newLength).from(UNIT_INCH).to(unitInput),
        _unit: unitInput,
      });
    }
    // if (hook) {
    //   return hook(merged, sourceElement, internalState, state).then(val => {
    //     return onUpdate(val);
    //   });
    // }
    // return onUpdate(merged);
  };
  // let _unit = "in";
  // let distanceText = convert(length).from(unit).to(rulerUnit);
  let distanceText = convert(length).from(unit).to(rulerUnit).toFixed(0);
  // let _ft_len = convert(_length).from('in').to('ft');
  // let _ft_val = Math.floor(_ft_len); _ft_len -= _ft_val;
  // let _in_val = convert(_ft_len).from('ft').to('in').toFixed(0);
  // let distanceText = `${_ft_val}'${_in_val}"`;
  let textLength = (distanceText.length + layer.unit.length) * 18;
  return (
    <g transform={transform} >
      <g id="ruler">
        <rect id={`ruler_rect_${IDBroker.acquireID()}`} style={BACKGROUNDSTYLE} x={length / 2 - textLength/2} y="-10" width={textLength} height="22" rx="1" ry="0.39"/>
        <text x={length / 2} y="3" transform={`scale(1, -1)`} style={STYLE_TEXT} fill={SHADE_LIGHT_BLUE_COLOR}>{Math.round(convert(distanceText).from("in").to(layer.unit) * 100) / 100}{layer.unit === "in" ? '"' : layer.unit}</text>
        {/* <FormNumberInput2
          value={distanceText}
          onChange={event => update(event.target.value, _unit, 0)}
          style={{
            width: textLength,
          }}
          precision={2}
        /> */}
      </g>
      <line x1="-4" y1="-4" x2="4" y2="4" style={STYLE} />
      <line x1={length - 4} y1="-4" x2={length + 4} y2="4" style={STYLE} />
      <line x1="0" y1="0" x2={(length - textLength) / 2 < 0 ? 0 : (length - textLength) / 2} y2="0" style={STYLE} />
      <line x1={(length + textLength) / 2 < 0 ? 0 : (length + textLength) / 2 < length ? (length + textLength) / 2 : length} y1="0" x2={length} y2="0" style={STYLE} />
    </g>
  );

}

Ruler.propTypes = {
  length: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  transform: PropTypes.string.isRequired
};
