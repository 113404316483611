import React from 'react'
import { Input, Button, Checkbox, Row } from 'antd'
import {bindActionCreators} from 'redux';
import connect from 'react-redux/es/connect/connect';
import * as Actions  from '../../../actions/user-actions';
import jwtService from '../jwtService'
import '../style.css'

class LoginForm extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      username: '',
      password: '',
    }
  }
  handleSubmit (e) {
    e.preventDefault();

    const {...values} = this.state;

    jwtService.signInWithEmailAndPassword(values)
    .then(response => {
        this.props.login(response.id, response.role, jwtService.getAccessToken());
    })
    .catch(error => {
        alert(error.info);
    });
  }

  handleUsernameChange(e) {
    e.target.setCustomValidity("");
    this.setState({username: e.target.value});
  }
  handlePasswordChange(e) {
    e.target.setCustomValidity("");
    this.setState({password: e.target.value});
  }
  handleInputInvalid(e, text) {
    e.target.setCustomValidity("");
    if (!e.target.validity.valid) {
        e.target.setCustomValidity(text);
    }
  }
  
  render() {
    return (
      <form onSubmit={(e)=>this.handleSubmit(e)} className="login-form">
        <Row className="login-form-row">
          <label className="form-label mb-0">Username</label>
            <Input  
              onChange={(e) => this.handleUsernameChange(e)}
              onInvalid={e => this.handleInputInvalid(e, "Please enter your name")}
              placeholder="Username"
            />
        </Row>
        <Row className="login-form-row">
          <label className="form-label mb-0">Password</label>
            <Input
              onChange={(e) => this.handlePasswordChange(e)}
              onInvalid={e => this.handleInputInvalid(e, "Please enter your password")}
              type="password"
              placeholder="Password"
            />
        </Row>
        <Row className="login-form-row-check">
          <Checkbox className="form-label">Remember me</Checkbox>
        </Row>
        <Row className="login-form-row">
          <div className="divider">-</div>
        </Row>
        <div className="form-actions">
          <Button type="primary" htmlType="submit" className="login-form-button">
            Sign in
          </Button>
        </div>
      </form>
    )    
  }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        login         : Actions.login,
    }, dispatch);
}

function mapStateToProps(state)
{
    return { }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
