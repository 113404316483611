import React from 'react';
import * as S from './styles';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm{
    border-radius: 10px;
  }
`;
class NewProjectPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      asking: sessionStorage.getItem('asking') ? sessionStorage.getItem('asking') : false
    };
  }
  
  _submit () {
    if(this.state.asking) sessionStorage.setItem('asking', true);
    this.props.newProject();
    this.closePanel();
  }
  closePanel () {
    this.props.handleNewProject(false);
  }
  render() {
    let { visible } = this.props;
    return (
      <StyledDialog open={visible} onClose={() => this.closePanel()}>
        <S.DialogTitle>New Project</S.DialogTitle>
        <S.DialogContent>Would you like to <strong>start a new project</strong>?</S.DialogContent>
        <S.DialogAction>
          <S.Button onClick={() => this.closePanel()}>
            Cancel
          </S.Button>
          <S.Button onClick={() => this._submit()}>
            OK
          </S.Button>
        </S.DialogAction>
      </StyledDialog>
    )
  }
}

export default NewProjectPopup