import React, { Component } from 'react';
import * as SharedStyle from '../../shared-style';
import { PROJECT_NAME_LENGTH_LIMIT, TEXT_COLOR_NEUTRAL_3, BG_COLOR_0, DEFAULT_FONT_FAMILY, SECONDARY_PURPLE_COLOR } from "../../constants";
import styled from 'styled-components';

const StyledInput = styled.input`
  display: block;
  padding: 15px 10px 12px 0px;
  width: 120px;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  color: ${TEXT_COLOR_NEUTRAL_3};
  background-color: ${BG_COLOR_0};
  border: 2px solid;
  text-align: right;
  float: right;
  border-radius: 5px;
  outline: 0;
  :focus{
    border-color: ${SECONDARY_PURPLE_COLOR};
  }
`

export default class FormTextInput extends Component {

  constructor(props) {
    super(props);
    this.state = { focus: false };
    this.input = React.createRef(null);
  }

  componentDidMount() {
    if (this.input.current) {
      this.input.current.select();
    }
  }

  render() {
    let { style, ...rest } = this.props;

    return (
      <StyledInput
        ref={this.input}
        onFocus={e => this.setState({ focus: true })}
        onBlur={e => this.setState({ focus: false })}
        style={style}
        // autoFocus
        type="text"
        {...rest}
        maxLength={PROJECT_NAME_LENGTH_LIMIT}
      />
    );
  }
}

FormTextInput.defaultProps = {
  style: {}
};
