import {
  BG_COLOR_1,
  BG_COLOR_HOVER,
  SECONDARY_PURPLE_COLOR,
  TEXT_COLOR_NEUTRAL_0,
  TEXT_COLOR_NEUTRAL_1,
  TEXT_COLOR_NEUTRAL_2,
  TEXT_COLOR_NEUTRAL_3,
  TEXT_COLOR_NEUTRAL_4,
  TEXT_COLOR_NEUTRAL_5,
  DEFAULT_FONT_FAMILY,
  SHADE_LIGHT_PURPLE_COLOR,
  SHADE_DARK_PURPLE_COLOR,
  BG_COLOR_0
} from '../../../constants';
import styled from 'styled-components';

export const WizardIntro = styled.div`
  position: absolute;
  left: 0;
  top: 60px;
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
`

export const IntroPlane = styled.div`
  position: relative;
  margin: auto;
  width: 700px;
  padding: 50px 70px;
  user-select: none;
  text-align: center;
  border-radius: 10px;  
  z-index: 10;
  flex-flow: column;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 5px -1px rgba(156, 154, 154, 0.2), 0px 6px 10px 0px rgba(156, 154, 154, 0.39), 0px 1px 18px 0px rgba(156, 154, 154, 0.12);
`

export const IntroMark = styled.div`
  margin-bottom: 20px;
`

export const IntroTitle = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 10px;
`

export const IntroDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`

export const IntroSelect = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
`

export const IntroItem = styled.div`
  display: block;
  text-align: center;
  img {
    cursor: pointer;
  }
`

export const IntroItemText = styled.div`
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
`

export const WizardStepWrapper = styled.div`
  position: absolute;
  left: 25px;
  top: 90px;
  z-index: 10;
  flex-flow: column;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 5px -1px rgba(156, 154, 154, 0.2), 0px 6px 10px 0px rgba(156, 154, 154, 0.39), 0px 1px 18px 0px rgba(156, 154, 154, 0.12);
  border-radius: 10px;
  padding: 25px;
  user-select: none;
  max-height: calc(100vh - 110px);
  display: flex;
  max-width: calc(100vw - 50px);
  overflow-y: auto;
`

export const TitleBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`

export const TitleBar = styled.div`
  font-size: 26px;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-weight: 700;
  line-height: 36px;
  color: ${TEXT_COLOR_NEUTRAL_0};
  text-align: left;
`

export const TitleBarButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const TitleBarButton = styled.button`
  display: flex;
  cursor: pointor;
  align-items: center;
  color: ${SECONDARY_PURPLE_COLOR};
  outline: 0;

@media screen and (min-width : 1024){font-size : 11px;}
@media screen and (max-width : 1024){font-size : 11px;}
@media screen and (min-width : 1366){font-size : 13px;}
@media screen and (max-width : 1366){font-size : 13px;}
@media screen and (min-width : 1440){font-size : 16px;}
@media screen and (max-width : 1440){font-size : 16px;}
  font-family: ${DEFAULT_FONT_FAMILY};
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
  padding: 8px 14px;
  border: 1px solid;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  line-height: 22px;
  text-align: center;
  background-color: ${BG_COLOR_1};
  :hover{
    background-color: ${BG_COLOR_0};
  }
  :active{
    background-color: ${BG_COLOR_0};
  }
  :focus{
    // box-shadow: 0px 0px 8px 2px ${SECONDARY_PURPLE_COLOR};
  }
`

export const ConfirmButton = styled.button`
  display: flex;
  cursor: pointor;
  align-items: center;
  color: ${BG_COLOR_1};
  background-color: ${SECONDARY_PURPLE_COLOR};

  @media screen and (min-width : 1024){font-size : 11px;}
  @media screen and (max-width : 1024){font-size : 11px;}
  @media screen and (min-width : 1366){font-size : 13px;}
  @media screen and (max-width : 1366){font-size : 13px;}
  @media screen and (min-width : 1440){font-size : 16px;}
  @media screen and (max-width : 1440){font-size : 16px;}
  font-family: ${DEFAULT_FONT_FAMILY};
  font-weight: 600;
  font-size : 16px;
  margin-left: 10px;
  padding: 8px 14px;
  border: 1px solid ${SECONDARY_PURPLE_COLOR};
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  line-height: 22px;
  text-align: center;
  :hover{
    background-color: ${SHADE_LIGHT_PURPLE_COLOR};
    border-color: ${SHADE_LIGHT_PURPLE_COLOR};
  }
  :active{
    background-color: ${SHADE_DARK_PURPLE_COLOR};
    border-color: ${SHADE_DARK_PURPLE_COLOR};
  }

`

export const StepBarWrapper = styled.div`
  align-items: flex-start;
  flex-direction: row;
  display: flex;
  padding: 25px;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: ${BG_COLOR_1};
`

export const StepBullet = styled.div`
  flex-direction: column;
  flex: 1;
  position: relative;
  min-width: 230px;
  max-width: 230px;
  display: flex;
  align-items: center;
`

export const StepBulletLabel = styled.span`
  margin-bottom: 5px;
  text-align: center;
  color: ${TEXT_COLOR_NEUTRAL_1};
  display: block;
  font-size: 16px;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-weight: 600;
  line-height: 22px;
  text-align: center;
`

export const StepBulletIcon = styled.img`
  justify-content: center;
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
  width: 16px;
`

export const StepBarIcon = styled.img`
  width: 250px;
  display: block;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`

export const ContentWrapper1 = styled.div`
  display: flex;
  margin-top: 20px;
`

export const ContentItem = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;
  img#check{
    display: none;
  }
  span#hint{
    display: none;
  }
  :hover{
    img#check{
      display: block;
      background-color: ${BG_COLOR_HOVER};
      z-index: 1;
    }
    span#hint{
      display: block;
    }
  }
`

export const ContentItemLabel = styled.span`
  margin-top: 10px;
  color: ${TEXT_COLOR_NEUTRAL_0};
  margin-bottom: 7px;
  font-family: ${DEFAULT_FONT_FAMILY};
/* @media screen and (min-width : 1024){font-size : 11px;}
@media screen and (max-width : 1024){font-size : 11px;}
@media screen and (min-width : 1366){font-size : 13px;}
@media screen and (max-width : 1366){font-size : 13px;}
@media screen and (min-width : 1440){font-size : 16px;}
@media screen and (max-width : 1440){font-size : 16px;} */
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
`

export const MeasurementUnitLabel = styled.span`
  position: absolute;
  left: 8px;

@media screen and (min-width : 1024){font-size : 11px;}
@media screen and (max-width : 1024){font-size : 11px;}
@media screen and (min-width : 1366){font-size : 13px;}
@media screen and (max-width : 1366){font-size : 13px;}
@media screen and (min-width : 1440){font-size : 16px;}
@media screen and (max-width : 1440){font-size : 16px;}
  line-height: 22px;
  padding: 0px 8px;
  top: -9px;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  background-color: ${BG_COLOR_1};
  color: ${TEXT_COLOR_NEUTRAL_1};
  font-family: ${DEFAULT_FONT_FAMILY};
`

export const MeasurementUnitSelect = styled.div`
  cursor: pointer;
  width: 170px;
  padding: 15px 15px;
  color: ${TEXT_COLOR_NEUTRAL_0};

@media screen and (min-width : 1024){font-size : 11px;}
@media screen and (max-width : 1024){font-size : 11px;}
@media screen and (min-width : 1366){font-size : 13px;}
@media screen and (max-width : 1366){font-size : 13px;}
@media screen and (min-width : 1440){font-size : 16px;}
@media screen and (max-width : 1440){font-size : 16px;}
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  font-family: ${DEFAULT_FONT_FAMILY};
  border-radius: 5px;
  border: 2px solid ${TEXT_COLOR_NEUTRAL_3};
`

export const MeasurementUnitOption = styled.div`
  padding: 12px 20px;

@media screen and (min-width : 1024){font-size : 11px;}
@media screen and (max-width : 1024){font-size : 11px;}
@media screen and (min-width : 1366){font-size : 13px;}
@media screen and (max-width : 1366){font-size : 13px;}
@media screen and (min-width : 1440){font-size : 16px;}
@media screen and (max-width : 1440){font-size : 16px;}
  font-family: ${DEFAULT_FONT_FAMILY};
  color: ${TEXT_COLOR_NEUTRAL_0};
  line-height: 22px;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  :hover{
    background-color: ${BG_COLOR_HOVER};
  }
`

export const SelectArrow = styled.img`
  position: absolute;
  right: 15px;
  width: 15px;
  height: 9px;
  background-color: ${TEXT_COLOR_NEUTRAL_3};
  -webkit-mask-image:url(${props=>props.maskImage});
  -webkit-mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
`

export const CeilingHeight = styled.input`
  padding: 15px 15px;
  padding-right: 105px;
  color: ${TEXT_COLOR_NEUTRAL_0};

@media screen and (min-width : 1024){font-size : 11px;}
@media screen and (max-width : 1024){font-size : 11px;}
@media screen and (min-width : 1366){font-size : 13px;}
@media screen and (max-width : 1366){font-size : 13px;}
@media screen and (min-width : 1440){font-size : 16px;}
@media screen and (max-width : 1440){font-size : 16px;}
  font-weight: 600;
  line-height: 22px;
  width: 150px;
  font-size: 16px;
  text-align: left;
  font-family: ${DEFAULT_FONT_FAMILY};
  border: 2px solid ${TEXT_COLOR_NEUTRAL_3};
  border-radius: 5px;
  outline: 0;
  :focus{
    border-color: ${SECONDARY_PURPLE_COLOR};
  }
  :hover{
    border-color: ${SECONDARY_PURPLE_COLOR};
  }
`

export const RoomContentImage = styled.img`
  height: 120px;
  width: 120px;
  z-index: 2;
  background-color: ${TEXT_COLOR_NEUTRAL_3};
  -webkit-mask-image:url(${props => props.maskImage});
  -webkit-mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
`

export const ContentCheckImage = styled.img`
  position: absolute;
  height: 120px;
  width: 120px;
  padding: 45px;
  cursor: pointer;
  z-index: 2;
`

export const ColorContentItem = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  img#check{
    display: none;
  }
  :hover{
    img#check{
      display: block;
      border: 2px solid ${SECONDARY_PURPLE_COLOR};
      border-radius: 50%;
    }
  }
`

export const ColorContentImage = styled.img`
  height: 70px;
  width: 70px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid ${TEXT_COLOR_NEUTRAL_4};
`

export const DoorStyleContentItem = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  width: max-content;
  margin-right: 30px;
  img#check{
    display: none;
  }
  :hover{
    img#check{
      display: block;
      background-color: ${TEXT_COLOR_NEUTRAL_5};
      z-index: 1;
    }
    div#item{
      background-color: ${BG_COLOR_HOVER};
    }
  }
`

export const DoorStyleContentImageWrapper = styled.div`
  height: 120px;
  width: 120px;
  z-index: 2;
  border-radius: 10px;
  border: 2px solid ${TEXT_COLOR_NEUTRAL_3};
`

export const DoorStyleContentImage = styled.img`
  height: 120px;
  background-color: ${TEXT_COLOR_NEUTRAL_3};
  width: 120px;
  border-radius: 10px;
  z-index: 2;
  -webkit-mask-image:url(${props=>props.maskImage});
  -webkit-mask-size: calc(100% - 50px) calc(100% - 50px);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: 25px 25px;
`

export const DoorCategoryContentImageWrapper = styled.div`
  width: 120px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  :hover{
    background-color: ${BG_COLOR_HOVER};
  }
`

export const DoorCategoryContentImage = styled.img`
  height: 120px;
  background-color: ${TEXT_COLOR_NEUTRAL_3};
  width: 120px;
  border-radius: 10px;
  z-index: 2;
  -webkit-mask-image:url(${props=>props.maskImage});
  -webkit-mask-size: calc(100% - 50px) calc(100% - 50px);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: 25px 25px;
`

export const FinishContentImage = styled.img`
  height: 120px;
  z-index: 2;
`

export const ContentWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  align-items: center;
`

export const DoorStyleWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  overflow-y: scroll;
  max-height: calc(100vh - 250px);
`

export const Title = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  font-family: ${DEFAULT_FONT_FAMILY};
  color: ${TEXT_COLOR_NEUTRAL_0};
`

export const MaskImageWrapper = styled.div`
  height: 120px;
  width: 120px;
  z-index: 2
`

export const DialogContent = styled.span`
  font-family: ${DEFAULT_FONT_FAMILY};
  color: ${TEXT_COLOR_NEUTRAL_1};
  padding: 10px 30px;
  padding-top: 30px;
`

export const DialogAction = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 30px 25px;
`

export const Button = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
  color: ${SECONDARY_PURPLE_COLOR};
  font-weight: bold;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 16px;
  border-radius: 20px;
  :hover{
    background-color: ${BG_COLOR_HOVER};
  }
`

export const Description = styled.span`
  font-family: ${DEFAULT_FONT_FAMILY};
  color: ${TEXT_COLOR_NEUTRAL_0};
@media screen and (min-width : 1024){font-size : 11px;}
@media screen and (max-width : 1024){font-size : 11px;}
@media screen and (min-width : 1366){font-size : 13px;}
@media screen and (max-width : 1366){font-size : 13px;}
@media screen and (min-width : 1440){font-size : 16px;}
@media screen and (max-width : 1440){font-size : 16px;}
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  font-size: 16px
`

export const FinishContentImageWrapper = styled.div`
  display: flex;
  height: 254px;
  width: 127px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
  max-height: 238px;
  min-height: 238px;
  :hover{
    background-color: ${TEXT_COLOR_NEUTRAL_5};
  }
`
